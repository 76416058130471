import { React, Component } from 'react'
import { connect } from "react-redux"
import * as actions from "../../../actions"
import axios from 'axios'
import { Container, Button, TableRow, TableHeaderCell, TableHeader, TableCell, TableBody, Table, Grid, GridColumn, Input, Confirm, Icon } from 'semantic-ui-react'

class Users extends Component {
    state = { }

    constructor(props) {
        super(props)
        this.handleDeleteClicked = this.handleDeleteClicked.bind(this)
        this.handleDeleteCancel = this.handleDeleteCancel.bind(this)
        this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this)
    }

    componentDidMount() {
        this.props.fetchUsers()
    }

    renderTableRows() {
        var data = []
        if (this.state.isSearching) {
            for (var x = 0; x < this.props.users.length; x++) {
                let user = this.props.users[x]
                if (!!user.name) {
                    if (user.name.toLowerCase().includes(this.state.searchString.toLowerCase())) {
                        data.push(user)
                        continue
                    }
                }
                if (!!user.email) {
                    if (user.email.toLowerCase().includes(this.state.searchString.toLowerCase())) {
                        data.push(user)
                        continue
                    }
                }
                if (!!user.role) {
                    if (user.role.toLowerCase().includes(this.state.searchString.toLowerCase())) {
                        data.push(user)
                        continue
                    }
                }
            }
        } else {
            data = this.props.users
        }
        var rows = []
        for (var i = 0; i < data.length; i++) {
            const user = data[i]
            var rank = ""
            if (!!user.rank) {
                rank = user.rank
            }
            if (!!user.name) {
                if (user.name === "Sean Tan" && user.email === "seantan.mouse@gmail.com") {
                    // continue
                }
            }
            rows.push(
                <TableRow key={i} style={{ cursor: 'pointer' }} onClick={() => this.handleEditClicked(user)}>
                    <TableCell>{!user.name ? user.givenName + ' ' + user.familyName : user.name}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{rank}</TableCell>
                    <TableCell collapsing>
                        <Button circular basic icon onClick={(e) => this.handleDeleteClicked(e, user)}>
                            <Confirm open={this.state.showDeleteConfirmationPopup} onCancel={this.handleDeleteCancel} onConfirm={this.handleDeleteConfirm} 
                            header="Confirmation" content={this.state.deleteConfirmContentString} cancelButton="Cancel" confirmButton="Delete"/>
                            <Icon name="trash alternate" />
                        </Button>
                    </TableCell>
                </TableRow>
            )
        }
        return rows
    }

    renderTable() {
        if (this.props.users == null) {
            return
        } else {
            return (
                <Table color='blue' celled striped style={{ marginTop: 20 }}>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell>Name</TableHeaderCell>
                        <TableHeaderCell width='5'>Email</TableHeaderCell>
                        <TableHeaderCell width='4'>Role</TableHeaderCell>
                        <TableHeaderCell></TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                {this.renderTableRows()}
                </TableBody>
              </Table>
            )
        }
    }

    render() {
        return (
            <Container>
                <Grid style={{ marginTop: 20 }}>
                    <GridColumn floated='left' width={5}><h1>Users</h1></GridColumn>
                    <GridColumn width={6}><Input fluid action={{ icon: 'search' }} placeholder='Search user...' onChange={this.handleChangeSearchField} /></GridColumn>
                    <GridColumn floated='right' width={5}>
                        <Button color='blue' floated='right' onClick={() => { this.props.onChangeItem("new-user")}}><Icon name='add'/>Create New User</Button>
                    </GridColumn>
                </Grid>
                {this.renderTable()}
            </Container>
        )
    }

    handleChangeSearchField = e => {
        const { value } = e.target
        if (value === "") {
            this.setState({ isSearching: false })
        } else {
            this.setState({ isSearching: true, searchString: value })
        }
    }

    handleEditClicked(user) {
        this.props.onChangeItem('edit-user', user)
    }

    handleDeleteClicked = (e, user) => {
        e.stopPropagation()
        if (this.state.showDeleteConfirmationPopup) return
        const contentString = "Delete user '" + user.name + "'?"
        this.setState({
            idToDelete: user._id,
            deleteConfirmContentString: contentString,
            showDeleteConfirmationPopup: true
        })
    }

    handleDeleteCancel = () =>
        this.setState({ showDeleteConfirmationPopup: false })

    async handleDeleteConfirm() {
        await axios.delete("/api/user/" + this.state.idToDelete).then(_ => {
            this.setState({ showDeleteConfirmationPopup: false })
            this.props.fetchUsers()
        })
        .catch(err => {
            console.log(`delete user failed with error: ${err}`)
            alert(`Failure deleting user, please try again. Error: ${err}`)
        })
    }
}

function mapStateToProps({ users }) {
    return { users }
}

export default connect(mapStateToProps, actions)(Users)