import axios from 'axios'
import { FETCH_USER, LOGIN, LOGIN_RESELLER, LOGOUT, FETCH_GAMES, FETCH_ORDERS, FETCH_RESELLERS, FETCH_USERS, TWOFA, FETCH_SETTINGS } from './types'
import { ENABLE_2FA } from '../utilities/enable2fa'

export const login = (email, password, code, callback) => dispatch => {
    axios({ method: 'post', url: '/api/login', data: { username: email, password: password, code } }) // passport.js requires it to be 'username'.
    .then((res) => { 
        if (res.status === 202 && ENABLE_2FA) {
            console.log("user needs to set up 2FA")
            console.log(res.data.user)
            dispatch({ type: LOGIN, payload: { invalidLogin: false, twoFASetupRequired: true, qr: res.data.data.qr, secret: res.data.data.secret, user: res.data.user }})
            callback()
            return
        } 
        dispatch({ type: LOGIN, payload: { invalidLogin: false, twoFASetupRequired: false, user: res.data.user }})
        callback()
    })
    .catch((err) => {
        dispatch({ type: LOGIN, payload: { invalidLogin: true } })
        callback()
    })
}

export const loginReseller = (email, password, code, callback) => dispatch => {
    axios({ method: 'post', url: '/api/login_reseller', data: { username: email, password: password, code } }) // passport.js requires it to be 'username'.
    .then((res) => { 
        if (res.status === 202 && res.data.user.twoFAEnabled) {
            console.log("user needs to set up 2FA")
            dispatch({ type: LOGIN_RESELLER, payload: { invalidLogin: false, twoFASetupRequired: true, qr: res.data.data.qr, secret: res.data.data.secret, user: res.data.user }})
            callback()
            return
        } 
        dispatch({ type: LOGIN_RESELLER, payload: { invalidLogin: false, twoFASetupRequired: false, user: res.data.user }})
        callback()
    })
    .catch((err) => {
        dispatch({ type: LOGIN_RESELLER, payload: { invalidLogin: true } })
        callback()
    })
}

export const twoFA = (user, secret, code, callback) => dispatch => {
    axios({ method: 'post', url: '/api/verify-totp', data: { user, secret, code } })
    .then((res) => {
        console.log('2FA success')
        dispatch({ type: TWOFA, payload: { invalidCode: false, verified2FA: true }})
        callback()
    })
    .catch((err) => {
        console.log('2FA failed')
        console.log(err)
        dispatch({ type: TWOFA, payload: { invalidCode: true, verified2FA: false } })
        callback()
    })
}

export const getCurrentUser = () => async dispatch => {
    const res = await axios.get('/api/current_user')
    dispatch({ type: FETCH_USER, payload: res.data })
} 

export const logout = (isAdmin) => async dispatch => {
    await axios.post('/api/logout')
    dispatch({ type: LOGOUT, payload: {} })
    window.location.href = isAdmin ? '/admin' : '/resellers'
}

export const fetchGames = () => async dispatch => {
    const res = await axios.get('/api/games')
    dispatch({ type: FETCH_GAMES, payload: res.data })
}

export const fetchOrders = (isAdmin, id) => async dispatch => {
    const res = isAdmin ? await axios.get('/api/orders') : await axios.get('/api/reseller/' + id + '/orders')
    dispatch({ type: FETCH_ORDERS, payload: res.data })
}

export const fetchResellers = () => async dispatch => {
    const res = await axios.get('/api/resellers')
    dispatch({ type: FETCH_RESELLERS, payload: res.data })
}

export const fetchUsers = () => async dispatch => {
    const res = await axios.get('/api/users')
    dispatch({ type: FETCH_USERS, payload: res.data })
}

export const fetchSettings = () => async dispatch => {
    const res = await axios.get('/api/settings')
    dispatch({ type: FETCH_SETTINGS, payload: res.data })
}