import { React, Component } from 'react'
import Login from './Login'

class LandingAdmin extends Component {
    render() {
        return (
            <Login userType='admin' history={this.props.history}/>
        )
    }
}

export default LandingAdmin