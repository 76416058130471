import React, { Component } from "react"
import { connect } from "react-redux"
import axios from 'axios'
import { Container, Breadcrumb, BreadcrumbSection, BreadcrumbDivider, Form, FormGroup, FormField, Input, TextArea, Button, Icon, Checkbox } from 'semantic-ui-react'

class CreateReseller extends Component {
    state = { name: '', phone: '', email: '', password: '', balance: 0, markupMLBBGlobal: 0, markupMLBBSpecial: 0, remarks: '', disableDiscounts: false }
    
    constructor(props) {
        super(props)
        this.handleSaveClicked = this.handleSaveClicked.bind(this)
        this.handleCancelClicked = this.handleCancelClicked.bind(this)
    }

    renderForm() {
        const { name, phone, email, password, balance, markupMLBBGlobal, markupMLBBSpecial, remarks, disableDiscounts } = this.state
        return (
            <Container>
                <Form>
                    <FormGroup widths={"equal"}>
                        <FormField control={Input} label='Name' name='name' value={name} placeholder='Name' onChange={this.handleChangeField}/>
                        <FormField control={Input} label='Phone' name='phone' value={phone} placeholder='Phone' onChange={this.handleChangeField}/>
                    </FormGroup>
                    <FormGroup widths={"equal"}>
                        <FormField control={Input} label='Email' name='email' value={email} placeholder='' onChange={this.handleChangeField}/>
                        <FormField control={Input} label='Password' type='password' name='password' value={password} placeholder='' onChange={this.handleChangeField}/>
                    </FormGroup>
                    <FormField control={Input} label='Balance' name='balance' value={balance} placeholder='' onChange={this.handleChangeField}/>
                    <FormGroup widths={"equal"}>
                        <FormField control={Input} label='Markup % for mlbb_global' name='markupMLBBGlobal' value={markupMLBBGlobal} placeholder='Markup % for mlbb_global' onChange={this.handleChangeField}/>
                        <FormField control={Input} label='Markup % for mlbb_special' name='markupMLBBSpecial' value={markupMLBBSpecial} placeholder='Markup % for mlbb_special' onChange={this.handleChangeField}/>
                    </FormGroup>
                    <FormField control={TextArea} rows='2' label='Remarks' name='remarks' value={remarks} placeholder='' onChange={this.handleChangeField}/>
                    <FormField control={Checkbox} toggle label='Disable Discounts' name='disableDiscounts' checked={disableDiscounts} onChange={this.handleToggle}/>
                </Form>
            </Container>
        )
    }

    render() {
        return (
            <Container style={{ marginTop: 35 }}>
                <Breadcrumb style={{ marginBottom: 30}} size='large'>
                    <BreadcrumbSection onClick={this.props.onCancel}>Resellers</BreadcrumbSection>
                    <BreadcrumbDivider icon='right chevron' />
                    <BreadcrumbSection active>Create New Reseller</BreadcrumbSection>
                </Breadcrumb>
                {this.renderForm()}
                <Button style={{ marginTop: 40, marginRight: 15 }} color='purple' onClick={this.handleSaveClicked}><Icon name='add'/>Create Reseller</Button><Button onClick={this.handleCancelClicked}><Icon name='reply'/>Back</Button>
            </Container>
        )
    }

    handleChangeField = e => {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }

    handleToggle = (e, data) => {
        this.setState({ [data.name]: data.checked, showSuccessMessage: false })
    }

    async handleSaveClicked() {
        const { name, phone, email, password, balance, markupMLBBGlobal, markupMLBBSpecial, remarks, disableDiscounts } = this.state
        const dict = { createdBy: this.props.user.name, createdAt: Date(), name, phone, email, password, balance: Number(balance), markup: { mlbb_global: Number(markupMLBBGlobal), mlbb_special: Number(markupMLBBSpecial) }, remarks, disableDiscounts, rank: 'Reseller' }
        await axios.post('/api/reseller', dict).then(_ => {
            this.props.onCreateSuccess()
        }).catch((err) => {
            console.log(`create reseller failed with error: ${err}`)
            alert(`Failure creating reseller, please try again. Error: ${err}`)
        })
    }

    handleCancelClicked() {
        this.props.onCancel()
    }
}

function mapStateToProps({ user }) {
    return { user }
}

export default connect(mapStateToProps)(CreateReseller)