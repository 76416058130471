import { React, Component } from 'react'
import { connect } from "react-redux"
import * as actions from "../actions"
import './main.css'
import { ENABLE_2FA } from '../utilities/enable2fa'
import { Container, Image, FormField, Button, Form, MessageHeader, Message } from 'semantic-ui-react'

class Login extends Component {
    state = { email: "", password: "", formSubmitting: false, formIncompleteError: false, invalidLogin: false }

    componentDidUpdate(prevProps) {
        if (prevProps.auth !== this.props.auth) {
            console.log(this.props.auth)
            if (this.props.auth != null) {
                this.setState({
                    invalidLogin: this.props.auth.invalidLogin
                })
                if (!this.props.auth.invalidLogin) {
                    if (this.props.userType === 'reseller') this.props.history.replace(this.props.auth.user.twoFASetupRequired ? '/2fa' : '/home')
                    else this.props.history.replace(ENABLE_2FA ? '/2fa' : '/home')
                }
            }
        }
    }

    renderLoginFailedMessage() {
        if (this.state.invalidLogin) {
            return (
                <Message negative>
                    <MessageHeader>Login failed</MessageHeader>
                    <p>Please check your email or password</p>
                </Message>
            )
        } else {
            return
        }
    }

    renderFormIncompleteMessage() {
        if (this.state.formIncompleteError) {
            return (
                <Message negative>
                    <MessageHeader>Please fill in email and password</MessageHeader>
                </Message>
            )
        } else {
            return 
        }
    }
    
    render() {
        return (
            <div className='bodyScoped'>  
            <Image style={{ marginTop: 60, marginBottom: 30, borderRadius: 22, backgroundColor: 'black' }} size='medium' centered src='/egc-logo.png'/>
            <Container text>
                <Form inverted loading={this.state.formSubmitting}>
                    <FormField>
                        <label>Email</label>
                        <input name='email' value={this.state.email} onChange={this.handleChangeField} placeholder='Email' />
                    </FormField>
                    <FormField>
                        <label>Password</label>
                        <input name='password' value={this.state.password} type="password" onChange={this.handleChangeField} placeholder='Password' />
                    </FormField>
                    {this.renderLoginFailedMessage()}
                    {this.renderFormIncompleteMessage()}
                    <Button style={{ marginTop: 30, backgroundColor: '#007BFF' }} fluid color='black' type='submit' onClick={this.handleSubmitClicked}>Login</Button>
                </Form>
            </Container>
            </div>
        )
    }

    handleChangeField = e => {
        const { name, value } = e.target
        this.setState({ [name]: value })
        const { email, password } = this.state
        if (email !== "" && password !== "" && this.state.formIncompleteError) {
            this.setState({ formIncompleteError: false })
        } 
    }

    handleSubmitClicked = () => {
        const { email, password } = this.state
        if (email === "" || password === "") {
            this.setState({ formIncompleteError: true })
            return
        }
        this.setState({ formSubmitting: true }, async function() {
            if (this.props.userType === 'admin') {
                this.props.login(email.toLowerCase(), password, "", () => {
                    this.setState({ formSubmitting: false })
                })
            } else if (this.props.userType === 'reseller') {
                this.props.loginReseller(email.toLowerCase(), password, "", () => {
                    this.setState({ formSubmitting: false })
                })
            } else {
                console.log('invalid userType')
            }
        })
    }
}

function mapStateToProps({auth}) {
    return { auth }
}

export default connect(mapStateToProps, actions)(Login)