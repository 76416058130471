import React, { Component } from "react"
import { connect } from "react-redux"
import * as actions from "../../actions"
import { MenuItem, Menu } from 'semantic-ui-react'
import { isAdmin } from '../../utilities/isAdmin'

class NavBar extends Component {
    state = { innerWidth: 0 }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this))
        this.setState({ innerWidth: window.innerWidth })
    }

    resize() {
        this.setState({ innerWidth: window.innerWidth })
    }

     render() {
        if (isAdmin(this.props)) {
            return (
                <Menu>
                    <MenuItem><img alt='logo' src='/egc-logo.png' /></MenuItem>
                    <MenuItem name='games' active={this.props.activeItem === 'games'} onClick={() => { this.props.onChangeItem("games")}}>Games</MenuItem>
                    <MenuItem name='orders' active={this.props.activeItem === 'orders'} onClick={() => { this.props.onChangeItem("orders")}}>Orders</MenuItem>
                    <MenuItem name='resellers' active={this.props.activeItem === 'resellers'} onClick={() => { this.props.onChangeItem("resellers")}}>Resellers</MenuItem> 
                    <MenuItem name='users' active={this.props.activeItem === 'users'} onClick={() => { this.props.onChangeItem("users")}}>Users</MenuItem>
                    <MenuItem name='settings' active={this.props.activeItem === 'settings'} onClick={() => { this.props.onChangeItem("settings")}}>Settings</MenuItem>
                    <MenuItem name='logout' onClick={this.handleLogout}>Logout</MenuItem>
                    <h4 style={{ marginLeft: 20, marginTop: 18 }}>Welcome, {this.props.user != null ? this.props.user.name : ''}</h4>
                </Menu>
            )
        } else {
            return (
                <Menu>
                    <MenuItem><img alt='logo' src='/egc-logo.png' /></MenuItem>
                    <MenuItem name='games' active={this.props.activeItem === 'games'} onClick={() => { this.props.onChangeItem("games")}}>Games</MenuItem>
                    <MenuItem name='orders' active={this.props.activeItem === 'orders'} onClick={() => { this.props.onChangeItem("orders")}}>Orders</MenuItem>
                    <MenuItem name='account' active={this.props.activeItem === 'account'} onClick={() => { this.props.onChangeItem("account")}}>Account</MenuItem>
                    { this.state.innerWidth > 600 ? <h4 style={{ marginLeft: 20, marginTop: 18 }}>Welcome, {this.props.user != null ? this.props.user.name : ''}</h4> : null }
                    { !!this.props.user && this.props.user.balance!== null ? <h4 style={{ marginLeft: this.state.innerWidth > 600 ? 30 : 20, marginTop: this.state.innerWidth > 600 ? 18 : 8 }}>Account Balance: ${numberWithCommas(this.props.user.balance.toFixed(2))}</h4> : null }
                </Menu>
            )
        }
     }
     
     handleLogout = () => {
         window.localStorage.setItem('verified2FA', null)
         this.props.logout(isAdmin(this.props))
     }
}

function mapStateToProps({ user }) {
    return { user }
}

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default connect(mapStateToProps, actions)(NavBar)