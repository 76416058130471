import React, { Component } from "react"
import Merchandise from "./Merchandise"
import { Container, Button, Icon, Form, FormGroup, FormField, Input, Checkbox, Divider } from 'semantic-ui-react'

class Package extends Component {
    state = { name: '', eliteDiasPackageName: '', description: '', remarks: '', isHidden: false, gameItems: [], isOutOfStock: false }

    constructor(props) {
        super(props)
        this.handleChangeField = this.handleChangeField.bind(this)
    }

    componentDidMount() {
        const { name, eliteDiasPackageName, description, remarks, isHidden, gameItems, isOutOfStock } = this.props.savedDict
        this.setState({ name, eliteDiasPackageName, description, remarks, isHidden, gameItems: gameItems || [], isOutOfStock })
    }

    render() {
        const { name, eliteDiasPackageName, description, remarks, isHidden, gameItems, isOutOfStock } = this.state
        return (
            <Container>
                <Button basic size='tiny' color='blue' onClick={this.props.backClicked}><Icon name='reply'/>Go back</Button>
                <h3 style={{ marginTop: 20 }}>{this.props.savedDict.name}</h3>
                <Form style={{ marginTop: 25 }}>
                    <FormGroup>
                        <FormField control={Checkbox} toggle label='Is Out of Stock' name='isOutOfStock' checked={isOutOfStock} onChange={this.handleToggle}/>
                        <FormField control={Checkbox} toggle label='Is Hidden' name='isHidden' checked={isHidden} placeholder='Is Hidden' onChange={this.handleToggle}/>
                    </FormGroup>
                    <FormGroup widths='equal'>
                        <FormField control={Input} label='Name' name='name' value={name} placeholder='name' onChange={this.handleChangeField}/>
                        <FormField control={Input} label='Elite Dias Name' name='eliteDiasPackageName' value={eliteDiasPackageName} placeholder='Elite Dias Package Name' onChange={this.handleChangeField}/>
                    </FormGroup>
                    <FormField control={Input} label='Description' name='description' value={description} placeholder='Description' onChange={this.handleChangeField}/>
                    <FormField control={Input} label='Remarks' name='remarks' value={remarks} placeholder='Remarks' onChange={this.handleChangeField}/>
                </Form>
                <Divider style={{ marginTop: 30 }}/>
                <h4>Merchandise:</h4>
                <Button color='blue' onClick={() => this.handleOptimiseSortingClicked()}>Optimise sorting</Button>
                <Merchandise cameFromPackage savedDict={{ gameItems }} onEdit={(gameItems) => this.handleMerchandiseEditGameItems(gameItems)}/>
            </Container>
        )
    }

    handleChangeField = e => {
        const { name, value } = e.target
        this.setState({ [name]: value }, () => this.props.onStateChange(this.state))
    }

    handleToggle = (e, data) => {
        console.log(data.name)
        console.log(data.checked)
        this.setState({ [data.name]: data.checked }, () => this.props.onStateChange(this.state))
    }

    handleMerchandiseEditGameItems(gameItems) {
        this.setState({ gameItems }, () => this.props.onStateChange(this.state))
    }

    handleOptimiseSortingClicked() {
        var sortedArr = [...this.state.gameItems]
        sortedArr.sort((a, b) => {
            // Extract numbers from names using regex
            const numA = a.name.match(/\d+/)?.[0]
            const numB = b.name.match(/\d+/)?.[0]
            // Case 1: Both have no numbers
            if (!numA && !numB) {
                return a.name.localeCompare(b.name) // Alphabetical sort
            }
            // Case 2: A has no number, B has number
            if (!numA && numB) {
                return -1 // A comes first
            }
            // Case 3: A has number, B has no number
            if (numA && !numB) {
                return 1 // B comes first
            }
            // Case 4: Both have numbers
            return Number(numA) - Number(numB) // Numeric sort
        })
        this.setState({ gameItems: sortedArr }, () => this.props.onStateChange(this.state))
    }
}

export default Package