import React, { Component } from "react"
import { Container, Grid, Input, Button, Card, Header, Segment, Form, Icon, Image } from "semantic-ui-react"
import { COLORS } from '../utilities/colours'

class DiamondLandingPage extends Component {
    state = {
        userId: "",
        serverId: "",
        selectedPackage: null,
        packages: [
            { id: 1, diamonds: 50, price: 0.99, bonus: 5 },
            { id: 2, diamonds: 150, price: 2.99, bonus: 15 },
            { id: 3, diamonds: 250, price: 4.99, bonus: 25 },
            { id: 4, diamonds: 500, price: 9.99, bonus: 65 },
            { id: 5, diamonds: 1000, price: 19.99, bonus: 100 },
        ],
    }

    renderPackages() {
        const { packages, selectedPackage } = this.state
        return packages.map((pkg) => (
            <Card key={pkg.id} link raised onClick={() => this.handlePackageSelect(pkg)} 
                style={{ width: "150px", margin: "10px", background: selectedPackage?.id === pkg.id ? COLORS.CARD_SELECTED : COLORS.CARD_DEFAULT, color: selectedPackage?.id === pkg.id ? COLORS.TEXT_WHITE : COLORS.TEXT_WHITE, 
                transition: "all 0.3s ease", boxShadow: "0 4px 8px rgba(0,0,0,0.3)" }} className="diamond-card">
                <Card.Content>
                    <Card.Header style={{ color: selectedPackage?.id === pkg.id ? '' : '' }}>{pkg.diamonds} Diamonds</Card.Header>
                    <Card.Meta style={{ color: selectedPackage?.id === pkg.id ? COLORS.TEXT_WHITE : COLORS.TEXT_DIM }}>
                        + {pkg.bonus} Bonus
                    </Card.Meta>
                    <Card.Description style={{ fontWeight: "bold" }}>
                        ${pkg.price.toFixed(2)}
                    </Card.Description>
                </Card.Content>
            </Card>
        ))
    }

    render() {
        const { userId, serverId, selectedPackage } = this.state
        return (
            <div style={{ background: COLORS.BACKGROUND, minHeight: "100vh", color: COLORS.TEXT_WHITE }}>
                <Container fluid style={{ padding: "20px 0" }}>
                <Image style={{ position: "absolute", top: 40, left: 40, margin: 0, padding: 0, zIndex: 1000, width: '13rem', height: '13rem' }} src='/egc-logo-transparent.png'/>
                <Image style={{ marginTop: 30, marginBottom: 50 }} centered size='large' src='/mobile-legends.png'/>
                    <Grid centered stackable style={{ marginTop: 0 }}>
                        <Grid.Column width={10}>
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Group widths="equal">
                                    <Form.Field>
                                        <label style={{ color: COLORS.PRIMARY_GOLD }}>User ID</label>
                                        <Input placeholder="Enter your Mobile Legends User ID" name="userId" value={userId} onChange={this.handleInputChange} style={{ background: COLORS.INPUT_BG, color: COLORS.TEXT_WHITE }}/>
                                    </Form.Field>
                                    <Form.Field>
                                        <label style={{ color: COLORS.PRIMARY_GOLD }}>Server ID</label>
                                        <Input placeholder="Enter your Server ID (e.g., 1234)" name="serverId" value={serverId} onChange={this.handleInputChange} style={{ background: COLORS.INPUT_BG, color: COLORS.TEXT_WHITE }}/>
                                    </Form.Field>
                                </Form.Group>
                                <p style={{ fontSize: "0.9em", color: COLORS.TEXT_DIM }}>Find your User ID and Server ID in-game under your profile.</p>
                                <Header as="h3" style={{ marginTop: 40, color: COLORS.TEXT_WHITE }}>Select a Package</Header>
                                <div style={{ marginTop: 35, display: "flex", flexWrap: "wrap", justifyContent: "center" }}>{this.renderPackages()}</div>
                                <Button size="large" style={{ marginTop: 60, background: selectedPackage ? COLORS.PRIMARY_GOLD : COLORS.TEXT_DIM, color: COLORS.BACKGROUND, transition: "background 0.3s ease" }} disabled={!selectedPackage} onClick={this.handleSubmit}>
                                    <Icon name="cart" /> Proceed to Payment
                                </Button>
                            </Form>
                        </Grid.Column>
                    </Grid>
                    <br/><br/><br/><br/><br/><br/><br/><br/>
                    <Segment textAlign="center" style={{ position: "fixed", bottom: 0, left: 0, right: 0, background: `linear-gradient(135deg, ${COLORS.SECONDARY_GOLD}, ${COLORS.PRIMARY_GOLD})`, padding: 20 }}>
                        <p style={{ color: 'black' }}>
                            © 2025 Emperor Gaming Credits | Contact us on{" "}
                            <a href="https://t.me/support" target="_blank" rel="noopener noreferrer" style={{ color: 'black' }}>
                                Telegram
                            </a>{" "}
                            or{" "}
                            <a href="https://wa.me/1234567890" target="_blank" rel="noopener noreferrer" style={{ color: 'black' }}>
                                WhatsApp
                            </a>
                        </p>
                    </Segment>
                </Container>
            </div>
        )
    }

    handleInputChange = (e, { name, value }) => {
        this.setState({ [name]: value })
    }

    handlePackageSelect = (pkg) => {
        this.setState({ selectedPackage: pkg })
    }

    handleSubmit = () => {
        const { userId, serverId, selectedPackage } = this.state
        if (!userId || !serverId || !selectedPackage) {
            alert("Please enter your User ID, Server ID, and select a package.")
            return
        }
        alert(`Processing payment for ${selectedPackage.diamonds} diamonds ($${selectedPackage.price}) for User ID: ${userId}, Server: ${serverId}`)
    }
}

export default DiamondLandingPage