import React, { Component } from "react"
import { connect } from "react-redux"
import axios from 'axios'
import { Container, Breadcrumb, BreadcrumbSection, BreadcrumbDivider, Form, FormGroup, FormField, Input, Select, TextArea, Button, Message, MessageHeader, Icon } from 'semantic-ui-react'

const rankOptions = [{ key: 'admin', text: 'Admin', value: 'Admin' }, { key: 'staff', text: 'Staff', value: 'Staff' }]

class User extends Component {
    state = { name: '', email: '', password: '', role: '', remarks: '', showSuccessMessage: false }
  
    constructor(props) {
        super(props)
        this.handleSaveClicked = this.handleSaveClicked.bind(this)
        this.handleCancelClicked = this.handleCancelClicked.bind(this)
    }

    componentDidMount() {
        if (this.props.isEditing) {
            var { name, givenName, familyName, email, password, rank, remarks } = this.props.userToEdit
            if (name === '' || !name) name = givenName + " " + familyName
            this.setState({ name, givenName, familyName, email, password, rank, remarks })
        }
    }

    renderRankField() {
        const { rank } = this.state
        if (!!rank) {
            if (rank.toLowerCase() === 'superadmin') {
                return(<FormField control={Input} readOnly label='Rank' name='rank' value={rank} placeholder=''/>)
            }
        }
        return(<FormField control={Select} label='Rank' name='rank' value={rank} options={rankOptions} placeholder='Select rank...' onChange={this.handleChangeRankDropDown}/>)
    }

    renderAPISecretField() {
        const { rank } = this.state
        if (rank === 'Reseller') return <div><FormField control={Input} label='API Secret' name='apiSecret' value={this.state.apiSecret} placeholder=''/><Button basic size='small' style={{ marginBottom: 20 }}>Generate API Secret</Button></div>
    }
    
    renderForm() {
        const { name, email, password, remarks } = this.state
        let type = this.props.isEditing ? "password" : ""
        return (
            <Container>
                <Form>
                    <FormGroup widths={"equal"}>
                        <FormField control={Input} label='Name' name='name' value={name} placeholder='' onChange={this.handleChangeField}/>
                        {this.renderRankField()}
                    </FormGroup>    
                    <FormGroup widths={"equal"}>
                        <FormField control={Input} label='Email' name='email' value={email} placeholder='' onChange={this.handleChangeField}/>
                        <FormField control={Input} label='Password' type={type} name='password' value={password} placeholder='' onChange={this.handleChangeField}/>
                    </FormGroup>
                    {this.renderAPISecretField()}
                    <FormField control={TextArea} rows='2' label='Remarks' name='remarks' value={remarks} placeholder='' onChange={this.handleChangeField}/>
                </Form>
            </Container>
        )
    }

    render() {
        var breadcrumbString = this.props.isEditing ? this.state.name : 'Create New User'
        var saveButtonString = this.props.isEditing ? 'Save Changes' : 'Create User'
        return (
            <Container>
                <Breadcrumb style={{ marginTop: 20, marginBottom: 20 }} size='large'>
                    <BreadcrumbSection link onClick={this.handleCancelClicked}>Users</BreadcrumbSection>
                    <BreadcrumbDivider icon='right chevron' />
                    <BreadcrumbSection active>{breadcrumbString}</BreadcrumbSection>
                </Breadcrumb>
                {this.renderForm()}
                { this.state.showSuccessMessage ? <Message success><MessageHeader>Changes saved</MessageHeader></Message>: null}
                <Button style={{ marginTop: 25, marginRight: 15 }} color='blue' onClick={this.handleSaveClicked}>{saveButtonString}</Button><Button onClick={this.handleCancelClicked}><Icon name='reply'/>Back</Button>
            </Container>
        )
    }

    handleChangeField = e => {
        if (this.props.viewOnly) {
            if (this.state[e.target.name] == null) {
                this.setState({ [e.target.name]: ""})
            }
            return
        }
        const { name, value } = e.target
        this.setState({ [name]: value, showSuccessMessage: false })
    }

    handleChangeRankDropDown = e => {
        this.setState({ rank: e.target.innerText })
    }

    async handleSaveClicked() {
        const { name, familyName, givenName, email, rank, password, remarks } = this.state
        const dict = { createdBy: this.props.user.name ? this.props.user.name : this.props.user.givenName, createdAt: Date(), name, familyName, givenName, email, rank, password, remarks }
        if (this.props.isEditing) {
            await axios.put("/api/user/" + this.props.userToEdit._id, dict).then(_ => {
                this.setState({ showSuccessMessage: true })
            }).catch(err => {
                console.log(`edit user failed with error: ${err}`)
                alert(`Failure editing user, please try again. Error: ${err}`)
            })
        } else {
            await axios.post('/api/user', dict).then(_ => {
                this.props.onCreateSuccess()
            }).catch((err) => {
                console.log(`create user failed with error: ${err}`)
                alert(`Failure creating user, please try again. Error: ${err}`)
            })
        }
    }

    handleCancelClicked() {
        this.props.onCancel()
    }
}

function mapStateToProps({ user }) {
    return { user }
}

export default connect(mapStateToProps)(User)