import React, { Component } from "react"
import axios from "axios"
import { Container, Breadcrumb, BreadcrumbSection, BreadcrumbDivider, Form, FormField, Input, Button, Icon } from 'semantic-ui-react'

class CreateGame extends Component {
    state = { name: '', isLoading: false }

    constructor(props) {
        super(props)
        this.handleSaveClicked = this.handleSaveClicked.bind(this)
    }

    render() {
        const { name } = this.state
        return (
            <Container>
                <br />
                <Breadcrumb size='large'>
                    <BreadcrumbSection onClick={this.props.onCancel}>Games</BreadcrumbSection>
                    <BreadcrumbDivider icon='right chevron' />
                    <BreadcrumbSection active>Create New Game</BreadcrumbSection>
                </Breadcrumb>
                <br /><br /><br />
                <Form>
                    <FormField control={Input} label='Game Name' name='name' value={name} placeholder='Game Name' onChange={this.handleChangeField}/>
                </Form>
                <br /><br />
                <Button color='yellow' style={{ marginRight: 15 }} onClick={this.handleSaveClicked} loading={this.state.isLoading}><Icon name='add'/>Create Game</Button>
                <Button onClick={this.handleCancelClicked}>Cancel</Button>
            </Container>
        )
    }

    handleChangeField = e => {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }

    handleSaveClicked() {
        const { name } = this.state
        const dict = { name }
        this.setState({ isLoading: true })
        axios.post('/api/game', dict).then((res) => {
            this.props.onCreateSuccess()
        }).catch((err) => {
            console.log(`create game failed with error: ${err}`)
            alert(`Failure creating game, please try again. Error: ${err}`)
        })
    }

    handleCancelClicked = () => {
        this.props.onCancel()
    }
}

export default CreateGame