import React, { Component } from "react"
import { Container, Form, FormGroup, FormField, Input, Divider, Segment, Button, Icon } from 'semantic-ui-react'

class Links extends Component {
    state = { newMetaUpdateLinks: [], generalGuideLinks: [], howToPlayLinks: [], esportsEventLinks: [], memesFunnyLinks: [], officialWebsiteLink: '', fandomLink: '', redditLink: '' }

    componentDidMount() {
        if (this.props.savedDict) { 
            const { newMetaUpdateLinks, generalGuideLinks, howToPlayLinks, esportsEventLinks, memesFunnyLinks, officialWebsiteLink, fandomLink, redditLink } = this.props.savedDict
            this.setState({ newMetaUpdateLinks, generalGuideLinks, howToPlayLinks, esportsEventLinks, memesFunnyLinks, officialWebsiteLink, fandomLink, redditLink })
        }
    }

    renderLinks(links, str) {
        var rows = []
        if (links === undefined) return
        for (const [i, link] of links.entries()) {
            if (i > 0) rows.push(<Divider style={{ marginTop: 25, marginBottom: 25 }}/>)
            rows.push(<div key={i}>
                <b>Video #{i+1}</b>
                <FormGroup style={{ marginTop: 20 }} widths='equal'>
                    <FormField control={Input} label='Name' name='newMetaUpdateLinks' value={link.name} placeholder='Name' onChange={this.handleChangeFieldVideoLink}/>
                    <FormField control={Input} label='Link' name='newMetaUpdateLinks' value={link.link} placeholder='Link' onChange={this.handleChangeFieldVideoLink}/>
                </FormGroup>
            </div>)
        }
        rows.push(<Button basic style={{ marginTop: links.length === 0 ? 0 : 8 }} color='blue' size='tiny' onClick={() => this.handleAddNewVideo(str)}><Icon name="add"/>Add New Video</Button>)
        return <div style={{ marginBottom: 25}}><h4>{str}</h4><Segment style={{ padding: 20 }}>{rows}</Segment></div>
    }

    render() {
        const { newMetaUpdateLinks, generalGuideLinks, howToPlayLinks, esportsEventLinks, memesFunnyLinks, officialWebsiteLink, fandomLink, redditLink } = this.state
        return (
            <Container>
                <Form style={{ marginTop: 25 }}>
                    {this.renderLinks(newMetaUpdateLinks, 'New Patch and Meta Updates')}
                    {this.renderLinks(generalGuideLinks, 'General Guide, Tips and Tricks')}
                    {this.renderLinks(howToPlayLinks, 'How to Play & Getting Started')}
                    {this.renderLinks(esportsEventLinks, 'e-Sports Events')}
                    {this.renderLinks(memesFunnyLinks, 'Memes, WTF Moments, Funny')}
                    <Divider style={{ marginTop: 30, marginBottom: 30 }}/>
                    <FormField control={Input} label='Official Game Website' name='officialWebsiteLink' value={officialWebsiteLink} placeholder='Official Game Website' onChange={this.handleChangeField}/>
                    <FormField control={Input} label='Fandom' name='fandomLink' value={fandomLink} placeholder='Fandom' onChange={this.handleChangeField}/>
                    <FormField control={Input} label='Reddit' name='redditLink' value={redditLink} placeholder='Reddit' onChange={this.handleChangeField}/>
                </Form>
            </Container>
        )
    }

    handleChangeField = e => {
        const { name, value } = e.target
        this.setState({ [name]: value }, () => this.props.onStateChange(this.state))
    }

    handleChangeFieldVideoLink = e => {

    }

    handleAddNewVideo(str) {
        const { newMetaUpdateLinks, generalGuideLinks, howToPlayLinks, esportsEventLinks, memesFunnyLinks } = this.state
        switch (str) {
            case 'New Patch and Meta Updates':
                var arr1 = [...newMetaUpdateLinks]
                arr1.push({ name: '', link: '' })
                this.setState({ newMetaUpdateLinks: arr1 })
                break
            case 'General Guide, Tips and Tricks':
                var arr2 = [...generalGuideLinks]
                arr2.push({ name: '', link: '' })
                this.setState({ generalGuideLinks: arr2 })
                break
            case 'How to Play & Getting Started':
                var arr3 = [...howToPlayLinks]
                arr3.push({ name: '', link: '' })
                this.setState({ howToPlayLinks: arr3 })
                break
            case 'e-Sports Events':
                var arr4 = [...esportsEventLinks]
                arr4.push({ name: '', link: '' })
                this.setState({ esportsEventLinks: arr4 })
                break
            case 'Memes, WTF Moments, Funny':
                var arr5 = [...memesFunnyLinks]
                arr5.push({ name: '', link: '' })
                this.setState({ memesFunnyLinks: arr5 })
                break
            default:
                break
        }
    }
}

export default Links