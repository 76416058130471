import { LOGIN, LOGIN_RESELLER, LOGOUT } from "../actions/types"

export default function _(state = null, action) {
    switch(action.type) {
        case LOGIN_RESELLER:
            return action.payload
        case LOGIN:
            return action.payload
        case LOGOUT:
            return action.payload
        default:
            return state
    }
}