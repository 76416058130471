import { React, Component } from 'react'
import { connect } from "react-redux"
import * as actions from "../../../actions"
import axios from 'axios'
import { Container, Button, TableRow, TableHeaderCell, TableHeader, TableCell, TableBody, Table, Grid, GridColumn, Input, Confirm, Icon, Popup, Form, FormField, Message } from 'semantic-ui-react'

class Resellers extends Component {
    state = { topUpAmount: 0, deductAmount: 0, showSuccessMessage: false, successMessageHeaderStr: '', topUpButtonLoading: false, deductButtonLoading: false }

    constructor(props) {
        super(props)
        this.handleDeleteClicked = this.handleDeleteClicked.bind(this)
        this.handleDeleteCancel = this.handleDeleteCancel.bind(this)
        this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this)
    }

    componentDidMount() {
        this.props.fetchResellers()
    }

    renderTableRows() {
        const data = this.state.isSearching ? this.props.resellers.filter((reseller) => ['name', 'email', 'phone'].some((field) => reseller[field]?.toLowerCase()?.includes(this.state.searchString.toLowerCase()))) : this.props.resellers
        var rows = []
        for (var i = 0; i < data.length; i++) {
            const reseller = data[i]
            rows.push(
                <TableRow key={i} style={{ cursor: 'pointer' }} onClick={() => this.handleResellerClicked(reseller)}>
                    <TableCell>{reseller.name}</TableCell>
                    <TableCell>{reseller.email}</TableCell>
                    <TableCell>
                        {(reseller.balance || 0).toFixed(2)}
                        <Popup style={{ textAlign: 'center' }} wide on='click' position="right center" trigger={ <Button style={{ marginLeft: 12, marginRight: 5 }} size='mini' circular basic icon onClick={(e) => e.stopPropagation() }><Icon name="plus" /></Button> }>
                            <div onClick={(e) => e.stopPropagation()}>
                                <Form style={{ marginTop: 12, marginLeft: 20, marginRight: 20, marginBottom: 20 }}>
                                    <FormField control={Input} label='Amount to Top Up' value={this.state.topUpAmount} placeholder='Amount to Top Up' onChange={this.handleChangeTopUpAmount}/>         
                                </Form>
                                <Button loading={this.state.topUpButtonLoading} fluid color='purple' style={{ marginBottom: 12 }} onClick={() => this.handleTopUpBalanceClicked(reseller)}><Icon name='add' />Top Up</Button>
                            </div>
                        </Popup>
                        <Popup style={{ textAlign: 'center' }} wide on='click' position="right center" trigger={ <Button size='mini' circular basic icon onClick={(e) => e.stopPropagation() }><Icon name="minus" /></Button> }>
                            <div onClick={(e) => e.stopPropagation()}>
                                <Form style={{ marginTop: 12, marginLeft: 20, marginRight: 20, marginBottom: 20 }}>
                                    <FormField control={Input} label='Amount to Deduct' value={this.state.deductAmount} placeholder='Amount to Deduct' onChange={this.handleChangeDeductAmount}/>         
                                </Form>
                                <Button loading={this.state.deductButtonLoading} fluid color='purple' style={{ marginBottom: 12 }} onClick={() => this.handleDeductBalanceClicked(reseller)}><Icon name='minus' />Deduct</Button>
                            </div>
                        </Popup>
                    </TableCell>
                    <TableCell collapsing>
                        <Button circular basic icon onClick={(e) => this.handleDeleteClicked(e, reseller)}>
                            <Confirm open={this.state.showDeleteConfirmationPopup} onCancel={this.handleDeleteCancel} onConfirm={this.handleDeleteConfirm} 
                            header="Confirmation" content={this.state.deleteConfirmContentString} cancelButton="Cancel" confirmButton="Delete"/>
                            <Icon name="trash alternate" />
                        </Button>
                    </TableCell>
                </TableRow>
            )
        }
        return rows
    }

    renderTable() {
        if (this.props.resellers == null) return
        return (
            <Table color='purple' celled striped style={{ marginTop: 20 }}>
            <TableHeader>
                <TableRow>
                    <TableHeaderCell>Name</TableHeaderCell>
                    <TableHeaderCell>Email</TableHeaderCell>
                    <TableHeaderCell>Balance</TableHeaderCell>
                    <TableHeaderCell></TableHeaderCell>
                </TableRow>
            </TableHeader>
            <TableBody>
            {this.renderTableRows()}
            </TableBody>
            </Table>
        )
    }

    render() {
        return (
            <Container>
                <Grid style={{ marginTop: 20 }}>
                    <GridColumn floated='left' width={5}><h1>Resellers</h1></GridColumn>
                    <GridColumn width={6}><Input fluid action={{ icon: 'search' }} placeholder='Search reseller...' onChange={this.handleChangeSearchField} /></GridColumn>
                    <GridColumn floated='right' width={5}>
                        <Button color='purple' floated='right' onClick={() => { this.props.onChangeItem("new-reseller")}}><Icon name='add'/>Create New Reseller</Button>
                    </GridColumn>
                </Grid>
                { this.state.showSuccessMessage ? <Message success header={this.state.successMessageHeaderStr}></Message> : null }
                {this.renderTable()}
            </Container>
        )
    }

    handleChangeSearchField = e => {
        this.setState({ showSuccessMessage: false })
        const { value } = e.target
        if (value === "") {
            this.setState({ isSearching: false })
        } else {
            this.setState({ isSearching: true, searchString: value })
        }
    }

    handleChangeTopUpAmount = e => {
        this.setState({ showSuccessMessage: false })
        const { value } = e.target
        this.setState({ topUpAmount: Number(value) })
    }

    handleChangeDeductAmount = e => {
        this.setState({ showSuccessMessage: false })
        const { value } = e.target
        this.setState({ deductAmount: Number(value) })
    }

    handleResellerClicked(reseller) {
        this.setState({ showSuccessMessage: false })
        this.props.onChangeItem('edit-reseller', reseller)
    }

    handleTopUpBalanceClicked = async (reseller) => {
        this.setState({ topUpButtonLoading: true })
        const dict = { amount: Number(this.state.topUpAmount) }
        await axios.post('/api/reseller/' + reseller._id + '/balance_topup', dict).then(_ => {
            this.props.fetchResellers()
            this.setState({ topUpButtonLoading: false, showSuccessMessage: true, successMessageHeaderStr: '$' + this.state.topUpAmount + ' top up success for ' + reseller.name, topUpAmount: 0 })
        }).catch((err) => {
            console.log(`top up reseller balance failed with error: ${err}`)
            alert(`Failure top up reseller balance, please try again. Error: ${err}`)
        })
    }

    handleDeductBalanceClicked = async (reseller) => {
        this.setState({ deductButtonLoading: true })
        const dict = { amount: Number(this.state.deductAmount) }
        await axios.post('/api/reseller/' + reseller._id + '/balance_deduct', dict).then(_ => {
            this.props.fetchResellers()
            this.setState({ deductButtonLoading: false, showSuccessMessage: true, successMessageHeaderStr: '$' + this.state.deductAmount + ' deduction success for ' + reseller.name, deductAmount: 0 })
        }).catch((err) => {
            console.log(`top up reseller balance failed with error: ${err}`)
            alert(`Failure top up reseller balance, please try again. Error: ${err}`)
        })
    }

    handleDeleteClicked = (e, reseller) => {
        e.stopPropagation()
        if (this.state.showDeleteConfirmationPopup) return
        const contentString = "Delete reseller '" + reseller.name + "'?"
        this.setState({
            idToDelete: reseller._id,
            deleteConfirmContentString: contentString,
            showDeleteConfirmationPopup: true
        })
    }

    handleDeleteCancel = () =>
        this.setState({ showDeleteConfirmationPopup: false })

    async handleDeleteConfirm() {
        await axios.delete("/api/reseller/" + this.state.idToDelete).then(_ => {
            this.setState({ showDeleteConfirmationPopup: false })
            this.props.fetchResellers()
        })
        .catch(err => {
            console.log(`delete reseller failed with error: ${err}`)
            alert(`Failure deleting reseller, please try again. Error: ${err}`)
        })
    }
}

function mapStateToProps({ resellers }) {
    return { resellers }
}

export default connect(mapStateToProps, actions)(Resellers)