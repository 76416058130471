import React, { Component } from "react"
import { Container, CardGroup, Card, Button, Icon, Grid, GridColumn, Input, Confirm, Divider, Label } from 'semantic-ui-react'

class Merchandise extends Component {
    state = { gameItems: [], isSearching: false, searchString: '', showConfirmCheckoutReseller: false, confirmationCheckoutResellerStr: '' }

    componentDidMount() {
        if (this.props.savedDict) { 
            const { gameItems } = this.props.savedDict
            this.setState({ gameItems: gameItems || [] })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.savedDict.gameItems !== this.props.savedDict.gameItems) {
            const { gameItems } = this.props.savedDict
            this.setState({ gameItems: gameItems || [] })
        }
    }

    renderResellerCard(item) {
        return (
            (<Card link raised stackable doubling image={item.image} header={item.name} description={
                <div>
                {'$' + roundUp2DP(this.getResellerPrice(item.cost, item.isDiscounted))}
                </div>
            } onClick={() => this.handleCardClickedReseller(item)}/>)
        )
    }

    renderCards() {
        const data = this.state.isSearching ? this.state.gameItems.filter(item => item.name?.toLowerCase().includes(this.state.searchString.toLowerCase())) : this.state.gameItems
        if (!data || data.length === 0 || data === undefined) return <div>No items available</div>
        var rows = []
        var doubleRechargeRows = []
        for (const [i, item] of data.entries()) {
            if (this.props.isReseller) {
                if (item.isHidden) continue
                if (item.isDoubleRecharge) doubleRechargeRows.push(this.renderResellerCard(item))
                else rows.push(this.renderResellerCard(item))
            } else {
                rows.push(<Card color={item.isDiscounted ? 'red' : ''} style={{ backgroundColor: item.isHidden ? '#ddd' : item.isDoubleRecharge ? '#FFFFC5' : '#fff' }} link raised stackable doubling image={item.image} header={item.name} 
                    meta={'Cost price: $' + (item.cost || 0).toFixed(2)} description={
                    <div>
                        { (item.isDiscounted || false) ? <Label style={{ marginTop: 10, marginBottom: 10 }} color='red' ribbon>Discounted</Label> : null }
                        {'Hidden: ' + item.isHidden}<br/>
                        {'2x recharge: ' + (item.isDoubleRecharge || false)}<br/><br/>
                        <Button color='blue' basic onClick={(e) => this.handleDoubleRechargeClicked(e, i)} fluid content="Double Recharge" size="mini"/>
                        <Button style={{ marginTop: 8}} color='blue' basic onClick={(e) => this.handleDiscountClicked(e, i)} fluid content="Discount" size="mini"/>
                    </div>
                    } extra={'Elite Dias Name: ' + item.eliteDiasName} onClick={() => this.handleCardClickedAdmin(i)}/>)
            } 
        }
        if (this.props.isReseller) {
            return (
                <div>
                    <CardGroup style={{ marginTop: 20 }} centered doubling itemsPerRow={8}>{rows}</CardGroup>
                    <Divider style={{ marginTop: 30, marginBottom: 20 }}/>
                    <h4>Double Recharge ⚡⚡</h4>
                    <CardGroup style={{ marginTop: 20 }} centered doubling itemsPerRow={8}>{doubleRechargeRows}</CardGroup>
                </div>
            )
        } else {
            return <CardGroup style={{ marginTop: 20 }} centered doubling itemsPerRow={8}>{rows}</CardGroup>
        }
    }

    render() {
        const str = this.props.isReseller ? '' : 'Total items: ' + this.state.gameItems.length
        return (
            <Container>
                { this.props.isReseller ? 
                <Grid style={{ marginTop: 20 }}>
                <GridColumn floated='left' width={5}><h2>{this.props.savedDict.name}</h2></GridColumn>
                <GridColumn width={6}><Input fluid action={{ icon: 'search' }} placeholder='Search product...' onChange={this.handleChangeSearchField}/></GridColumn>
                { this.props.hasPackages ? <GridColumn floated='right' width={5}><Button floated='right' onClick={this.props.resellerBackClicked} color='blue'><Icon name='reply'/>Back</Button></GridColumn> : null}
            </Grid> : null }
                {this.renderCards()}
                <br/>{str}
                <Confirm open={this.state.showConfirmCheckoutReseller} onCancel={() => this.handleCheckoutResellerCancel()} onConfirm={() => this.handleCheckoutResellerConfirm()} header="Confirmation" content={this.state.confirmationCheckoutResellerStr} 
                        cancelButton="Cancel" confirmButton="Place Order"/>
            </Container>
        )
    }

    getResellerPrice(cost, discounted) {
        const markup = (discounted && !this.props.disableDiscounts) ? (Number(this.props.markup) - 1.25) : Number(this.props.markup)
        return Number(cost) + (markup/100 * Number(cost))
    }

    handleChangeSearchField = e => {
        const { value } = e.target
        if (value === "") {
            this.setState({ isSearching: false })
        } else {
            this.setState({ isSearching: true, searchString: value })
        }
    }

    handleCardClickedReseller(item) {
        console.log(item)
        this.setState({ selectedItem: item, showConfirmCheckoutReseller: true, confirmationCheckoutResellerStr: `Proceed with: ${item.name.replace('Diamonds', '💎')} ($${roundUp2DP(this.getResellerPrice(item.cost, item.isDiscounted))})?` })
    }

    handleCardClickedAdmin(i) {
        if (!this.props.cameFromPackage) return
        var items = [...this.state.gameItems]
        items[i].isHidden = !items[i].isHidden
        this.setState({ gameItems: items }, this.props.onEdit(items))
    }

    handleDoubleRechargeClicked(e, i) {
        e.stopPropagation()
        var items = [...this.state.gameItems]
        items[i].isDoubleRecharge = !items[i].isDoubleRecharge
        this.setState({ gameItems: items }, this.props.onEdit(items))
    }

    handleDiscountClicked(e, i) {
        e.stopPropagation()
        var items = [...this.state.gameItems]
        items[i].isDiscounted = !items[i].isDiscounted
        this.setState({ gameItems: items }, this.props.onEdit(items))
    }

    handleCheckoutResellerConfirm() {
        this.setState({ showConfirmCheckoutReseller: false }, () => {
            this.props.placeOrder(this.state.selectedItem)
        })
    }

    handleCheckoutResellerCancel() {
        this.setState({ showConfirmCheckoutReseller: false })
    }
}

function roundUp2DP(num) {
    return Math.ceil(num * 100) / 100
}

export default Merchandise