import { React, Component } from 'react'
import { connect } from "react-redux"
import * as actions from "../../../actions"
import { isAdmin } from '../../../utilities/isAdmin'
import { Container, Button, TableRow, TableHeaderCell, TableHeader, TableCell, TableBody, Table, Grid, GridColumn, Input, Loader, CardGroup, Card, Icon } from 'semantic-ui-react'

class Games extends Component {
    state = { isSearching: false, searchString: '' }

    constructor(props) {
        super(props)
        this.props.fetchGames()
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this))
        this.setState({ innerWidth: window.innerWidth })
    }

    resize() {
        this.setState({ innerWidth: window.innerWidth })
    }

    renderTableRows() {
        const data = this.getData() || []
        var rows = []
        for (const [i, game] of data.entries()) {
            rows.push(  <TableRow key={i} style={{ cursor: 'pointer' }} onClick={() => this.handleEditClicked(game)}>
                            <TableCell>{game.name}</TableCell>
                            <TableCell>{game.isHidden ? '❗Yes❗' : '-' }</TableCell>
                            <TableCell>{game.isOutOfStock ? '❗Yes❗' : '-' }</TableCell>
                            <TableCell>{game.priority}</TableCell>
                        </TableRow>)
        }
        return rows
    }

    renderTable() {
        if (this.props.games == null) {
            return <Loader active size="large">Loading</Loader>
        } else {
            return (
                <Table color='yellow' celled striped style={{ marginTop: 20 }}>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell>Name</TableHeaderCell>
                        <TableHeaderCell width='2'>Is Hidden</TableHeaderCell>
                        <TableHeaderCell width='2'>Is Out of Stock</TableHeaderCell>
                        <TableHeaderCell width='2'>Priority</TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                {this.renderTableRows()}
                </TableBody>
              </Table>
            )
        }
    }

    renderCards() {
        const data = this.getData() || []
        console.log(this.state.innerWidth)
        var rows = []
        var height = '39.2rem'
        if (this.state.innerWidth < 1200) height = '32.5rem'
        if (this.state.innerWidth < 992) height = '25rem'
        if (this.state.innerWidth < 700) height = '23rem'
        if (this.state.innerWidth < 630) height = '21rem'
        if (this.state.innerWidth < 575) height = '19rem'
        if (this.state.innerWidth < 523) height = '17rem'
        if (this.state.innerWidth < 500) height = '16rem'
        if (this.state.innerWidth < 480) height = '15rem'
        if (this.state.innerWidth < 450) height = '14rem'
        if (this.state.innerWidth < 400) height = '12.5rem'
        if (this.state.innerWidth < 350) height = '11rem'

        // FOR 6 CARDS PER ROW
        // var height = '7rem'
        // if (this.state.innerWidth < 1007) height = '9.4rem'
        // if (this.state.innerWidth < 783) height = '16rem'
        // if (this.state.innerWidth < 525) height = '10rem'
        // if (this.state.innerWidth < 450) height = '8rem'
        for (const [i, game] of data.entries()) {
            if (game.isHidden) continue
            rows.push(<Card onClick={() => { this.props.resellerGameClicked(game)}} key={i} link raised stackable doubling image={{ src: game.gameImage, style: { backgroundColor: 'white', height, width: '100%', objectFit: 'fill' } }} 
                        header={game.name} description={game.tagline} extra={game.platforms.join(', ')}/>)

        }
        return <CardGroup style={{ marginTop: 20 }} centered doubling itemsPerRow={1}>{rows}</CardGroup>
    }

    render() {
        return (
            <Container>
                <Grid style={{ marginTop: 20 }}>
                    <GridColumn floated='left' width={5}><h1>Games</h1></GridColumn>
                    <GridColumn width={6}><Input fluid action={{ icon: 'search' }} placeholder='Search game...' onChange={this.handleChangeSearchField} /></GridColumn>
                    <GridColumn floated='right' width={5}>
                        { isAdmin(this.props) ? <Button color='yellow' floated='right' onClick={() => { this.props.onChangeItem("new-game")}}><Icon name='add'/>Create New Game</Button> : null }
                    </GridColumn>
                </Grid>
                { isAdmin(this.props) ? this.renderTable() : this.renderCards() }
            </Container>
        )
    }

    getData() {
        var data = []
        if (this.state.isSearching) {
            for (var x = 0; x < this.props.games.length; x++) {
                let game = this.props.games[x]
                if (!!game.name) {
                    if (game.name.toLowerCase().includes(this.state.searchString.toLowerCase())) {
                        data.push(game)
                        continue
                    }
                }
            }
        } else {
            data = this.props.games
        }
        return data
    }

    handleChangeSearchField = e => {
        const { value } = e.target
        if (value === "") {
            this.setState({ isSearching: false })
        } else {
            this.setState({ isSearching: true, searchString: value })
        }
    }

    handleEditClicked(game) {
        this.props.onChangeItem('edit-game', game)
    }
}

function mapStateToProps({ games, user, settings }) {
    return { games, user, settings }
}

export default connect(mapStateToProps, actions)(Games)