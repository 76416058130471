import { React, Component } from 'react'
import { connect } from "react-redux"
import * as actions from "../actions"
import './main.css'
import { Container, HeaderContent, Header, Image, FormField, Button, Form, MessageHeader, Message } from 'semantic-ui-react'

var counter = 0

class TwoFAPage extends Component {
    state = { code: "", formSubmitting: false, formIncompleteError: false, invalidCode: false, scanningDone: false }

    componentDidUpdate(previousProps) {
        let props = this.props
        if (previousProps.verifyToken !== this.props.verifyToken) {
            counter += 1
            this.setState({
                invalidCode: props.verifyToken.invalidCode
            })
            if (!props.verifyToken.invalidCode) {
                this.props.history.replace("/home")
            } else if (counter > 2) {
                counter = 0
                this.props.history.replace("/")
            }
        }
    }

    renderLoginFailedMessage() {
        if (this.state.invalidCode) {
            return (
                <Message negative>
                    <MessageHeader>Verification failed</MessageHeader>
                    <p>Please try again</p>
                </Message>
            )
        } else {
            return
        }
    }

    renderFormIncompleteMessage() {
        if (this.state.formIncompleteError) {
            return (
                <Message negative>
                    <MessageHeader>Please enter the verification code as shown in your Authenticator App</MessageHeader>
                </Message>
            )
        } else {
            return 
        }
    }

    renderQRCode() {
        if (!!this.props.auth) {
            if (this.props.auth.twoFASetupRequired && !this.state.scanningDone) {
                return <div style={{ backgroundColor: 'white' }} dangerouslySetInnerHTML={{__html: this.props.auth.qr}} />
            }
        }
    }

    renderBackToQRButton() {
        if (this.props.auth.twoFASetupRequired) {
            return <Button style={{ marginTop: '15px' }} fluid onClick={this.handleBackToQRCodeClicked}>Back to QR Code</Button>
        }
    }

    renderContent() {
        if (this.props.auth.twoFASetupRequired && !this.state.scanningDone) {
            return (
                <div>
                    <br />
                    <Button color='black' style={{ backgroundColor: '#007BFF' }} fluid onClick={this.handleScanQRDoneClicked}>Done</Button>
                </div>
            )
        } else {
            return (
                <Form inverted loading={this.state.formSubmitting}>
                    <FormField>
                        <label>Verification Code</label>
                        <input name='code' value={this.state.code} onChange={this.handleChange} placeholder='Code' />
                    </FormField>
                    {this.renderLoginFailedMessage()}
                    {this.renderFormIncompleteMessage()}
                    <br />
                    <Button fluid color='black' style={{ backgroundColor: '#007BFF' }} type='submit' onClick={this.handleSubmitClicked}>Submit</Button>
                    {this.renderBackToQRButton()}
                </Form>
            )
        }
    }
    
    render() {
        var headerString = "Please enter verification code from Google Authenticator App"
        if (this.props.auth.twoFASetupRequired  && !this.state.scanningDone) headerString = "Please scan this QR code in the Google Authenticator App:"
        return (
            <div className='bodyScoped'>  
            <br /><br />
            <Image style={{ borderRadius: 30, backgroundColor: 'white' }} size='medium' centered src='/egc-logo.png'/>
            <Header style={{ marginTop: 35, color: 'white' }} as='h2' icon textAlign='center'>
                <HeaderContent>{headerString}</HeaderContent>
            </Header>
            <br />
            <Container style={{ width: '300px' }} text>
            {this.renderQRCode()}
            {this.renderContent()}
            </Container>
            </div>
        )
    }
    
    handleChange = e => {
        const { name, value } = e.target
        this.setState({ [name]: value })
        const { code } = this.state
        if (code !== "" && this.state.formIncompleteError) {
            this.setState({ formIncompleteError: false })
        } 
    }

    handleSubmitClicked = () => {
        const { code } = this.state
        if (code === "") {
            this.setState({ formIncompleteError: true })
            return
        }
        this.setState({ formSubmitting: true }, async function() {
            let user = this.props.auth.user
            var secret = user.secret
            if (this.props.auth.twoFASetupRequired) {
                secret = this.props.auth.secret
            }
            console.log(user)
            this.props.twoFA(user, secret, code, () => {
                this.setState({ formSubmitting: false })
            })
            return
        })
    }

    handleScanQRDoneClicked = () => {
        this.setState({ scanningDone: true } )
    }

    handleBackToQRCodeClicked = () => {
        this.setState({ scanningDone: false })
    }
}

function mapStateToProps({ auth, verifyToken }) {
    return { auth, verifyToken }
}

export default connect(mapStateToProps, actions)(TwoFAPage)