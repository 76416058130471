import React, { Component } from "react"
import { connect } from "react-redux"
import * as actions from "../../../actions"
import Merchandise from "./Merchandise"
import axios from 'axios'
import { Container, Button, Icon, Form, FormGroup, FormField, Input, Divider, Message } from 'semantic-ui-react'

class ResellerMLBB extends Component {
    state = { userID: '', serverID: '', userName: '', mlbb_globalIsValid: false, mlbb_specialIsValid: false, isLoadingVerify: false, showInvalidUserMessage: false, showPurchaseSuccessMessage: false, showOutOfStockMessage: false }

    constructor(props) {
        super(props)
        this.handleChangeField = this.handleChangeField.bind(this)
    }

    render() {
        const game = this.props.game
        const { userID, serverID, mlbb_globalIsValid, mlbb_specialIsValid, isLoadingVerify, userName, showOutOfStockMessage } = this.state
        return (
            <Container>
                <h2 style={{ marginTop: 30 }}>{game.name}</h2>
                <Form style={{ marginTop: 25 }}>
                    <FormGroup widths='equal'>
                        <FormField required control={Input} label='User ID' name='userID' value={userID} placeholder='User ID' onChange={this.handleChangeField}/>
                        <FormField required control={Input} label='Server ID' name='serverID' value={serverID} placeholder='Server ID' onChange={this.handleChangeField}/>
                    </FormGroup>
                    { mlbb_globalIsValid || mlbb_specialIsValid ? <h4>{userName} ✅</h4> : null }
                    <Button loading={isLoadingVerify} style={{ marginTop: 15}} color='blue' onClick={this.verifyButtonClicked}><Icon name='gem'/>Verify</Button>
                </Form>
                { showOutOfStockMessage ? <Message style={{ marginTop: 30, marginBottom: 30 }} negative header={'Items for ' + this.getPackageName() + ' are currently Out of Stock.'} content='Please try again later.'/> : null }
                { this.state.showInvalidUserMessage ? <Message style={{ marginTop: 20 }} negative header='Invalid user' content='Please check and try again.'/> : null }
                { this.state.showPurchaseSuccessMessage ? <Message style={{ marginTop: 20 }} success header={'Placed order successfully for User: ' + userName} content='You may track the order status in the Orders page.'/> : null }
                { (mlbb_globalIsValid || mlbb_specialIsValid) && !showOutOfStockMessage ? <div>
                    <Divider style={{ marginTop: 30 }}/>
                    <h4>Eligible for {this.getPackageName()}:</h4>
                    <Merchandise isReseller disableDiscounts={this.props.user.disableDiscounts} userName={userName} savedDict={{ gameItems: this.getRelevantGameItems() }} markup={this.getResellerMarkup()} placeOrder={(item) => this.handlePurchaseItem(item)}/></div> : null }
                
                <br/><br/><br/><br/><br/><br/>
                <div style={{ position: "fixed", bottom: 0, left: 0, right: 0, background: "#fff", borderTop: "2px solid #ccc", padding: "10px", zIndex: 100 }}>
                    <Container>
                        <Button style={{ marginTop: 10, marginRight: 15, marginBottom: 15 }} color='blue' onClick={this.props.backClicked}><Icon name="reply" />Back</Button>
                    </Container>
                </div>
            </Container>
        )
    }

    handleChangeField = e => {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }

    handleToggle = (e, data) => {
        this.setState({ [data.name]: data.checked })
    }
    
    getPackageName() {
        const { mlbb_globalIsValid } = this.state
        for (const pkg of this.props.game.packages) {
            if (pkg.eliteDiasPackageName === (mlbb_globalIsValid ? 'mlbb_global' : 'mlbb_special') ) return pkg.name
        }
    }

    getRelevantGameItems() {
        const { mlbb_globalIsValid } = this.state
        for (const pkg of this.props.game.packages) {
            if (pkg.eliteDiasPackageName === (mlbb_globalIsValid ? 'mlbb_global' : 'mlbb_special') ) return pkg.gameItems
        }
    }

    isPackageOutOfStock(name){
        for (const pkg of this.props.game.packages) {
            if (pkg.eliteDiasPackageName === name) return pkg.isOutOfStock
        }
    }

    getResellerMarkup() {
        return this.state.mlbb_globalIsValid ? this.props.markup.mlbb_global : this.props.markup.mlbb_special
    }

    getResellerPrice(cost, discounted) {
        const markup = (discounted && !this.props.disableDiscounts) ? Number(this.getResellerMarkup()) - 1.25 : Number(this.getResellerMarkup())
        return Number(cost) + (markup/100 * Number(cost))
    }
    
    verifyButtonClicked = async () => {
        const { userID, serverID } = this.state
        if (userID === '' || serverID === '') return
        this.setState({ isLoadingVerify: true, showPurchaseSuccessMessage: false })
        const dict = { userid: userID, serverid: serverID }
        await axios.post("/api/reseller/verify_id/mlbb", dict).then(res => {
            console.log(res.data)
            const { mlbb_global, mlbb_special } = res.data
            if (mlbb_global === 'valid' && this.isPackageOutOfStock('mlbb_global')) this.setState({ showOutOfStockMessage: true })
            else if (mlbb_special === 'valid' && this.isPackageOutOfStock('mlbb_special')) this.setState({ showOutOfStockMessage: true })
            else this.setState({ showOutOfStockMessage: false })
            this.setState({ showInvalidUserMessage: (mlbb_global === 'invalid' && mlbb_special === 'invalid') })
            this.setState({ mlbb_globalIsValid: mlbb_global === 'valid', mlbb_specialIsValid: mlbb_special === 'valid', userName: mlbb_global === 'valid' ? res.data.name1 : res.data.name2, isLoadingVerify: false })
        }).catch(err => {
            console.log(`verify ID failed with error: ${err}`)
            alert(`Failure verify ID, please try again. Error: ${err}`)
            this.setState({ isLoadingVerify: false })
        })
    }

    handlePurchaseItem = async (item) => {
        console.log(item)
        const { userID, serverID, mlbb_globalIsValid, userName } = this.state
        this.setState({ isLoadingVerify: true })
        const dict = { reseller: { name: this.props.user.name, id: this.props.user._id }, game: mlbb_globalIsValid ? 'mlbb_global' : 'mlbb_special', userid: userID, serverid: serverID, denom: item.eliteDiasName, userName, 
                                    itemName: item.name, amount: roundUp2DP(this.getResellerPrice(item.cost, item.isDiscounted)), cost: item.cost, package: this.getPackageName() }
        await axios.post("/api/reseller/place_order/mlbb", dict).then(res => {
            console.log(res.data)
            this.props.getCurrentUser()
            this.setState({ userID: '', serverID: '', mlbb_globalIsValid: false, mlbb_specialIsValid: false, isLoadingVerify: false, showPurchaseSuccessMessage: true })
        }).catch(err => {
            if (err.response) {
                const statusCode = err.response.status
                console.log(`Verify ID failed with status: ${statusCode}, error: ${err.response.data}`)
                if (statusCode === 403) {
                    alert('Insufficient balance in your account, please check.')
                } else {
                    alert(`Failure verify ID, please try again. Status: ${statusCode}, Error: ${err.response.data}`)
                }
            } else {
                // No response (e.g., network error)
                console.log(`Verify ID failed with error: ${err.message}`)
                alert(`Failure verify ID, please try again. Error: ${err.message}`)
            }
            this.setState({ isLoadingVerify: false })
        })
    }
}

function roundUp2DP(num) {
    return Math.ceil(num * 100) / 100
}

function mapStateToProps({ user }) {
    return { user }
}

export default connect(mapStateToProps, actions)(ResellerMLBB)