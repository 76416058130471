import { React, Component } from 'react'
import { connect } from "react-redux"
import * as actions from "../../../actions"
import axios from 'axios'
import { format } from 'date-fns';
import { isAdmin } from '../../../utilities/isAdmin'
import { saveAs } from "file-saver"
import writeXlsxFile from 'write-excel-file'
import { Container, TableRow, TableHeaderCell, TableHeader, TableCell, TableBody, Table, Grid, GridColumn, Input, Button, Icon, Popup, Pagination } from 'semantic-ui-react'

class Orders extends Component {
    state = { isRefreshingAllStatus: false, itemsPerPage: 20, activePage: 0 }

    componentDidMount() {
        this.props.fetchOrders(isAdmin(this.props), this.props.user._id)
        window.addEventListener("resize", this.resize.bind(this))
        this.setState({ innerWidth: window.innerWidth })
    }

    resize() {
        this.setState({ innerWidth: window.innerWidth })
    }

    renderPagination() {
        if (this.props.orders == null) {
            return
        }
        const pagesRequired = Math.ceil(this.props.orders.length / this.state.itemsPerPage)
        return (
            <Pagination defaultActivePage={1} totalPages={pagesRequired} siblingRange={2} onPageChange={this.handlePageChange}/>
        )
    }

    renderTableRows() {
        const data = this.state.isSearching ? this.props.orders.filter((order) =>
        ['id', 'date', 'game', 'package', 'userName', 'userID', 'serverID', 'status', 'reseller.name'].some((field) => {
            const value = field.includes('.') ? field.split('.').reduce((obj, key) => obj?.[key], order) : order[field]
            return value?.toLowerCase?.()?.includes(this.state.searchString.toLowerCase())
        })).slice(this.state.activePage*this.state.itemsPerPage, this.state.activePage*this.state.itemsPerPage+this.state.itemsPerPage) 
        : this.props.orders.slice(this.state.activePage*this.state.itemsPerPage, this.state.activePage*this.state.itemsPerPage+this.state.itemsPerPage)
        var rows = []
        for (var i = 0; i < data.length; i++) {
            const order = data[i]
            const oldBalance = order.reseller.oldBalance || 0
            const newBalance = order.reseller.newBalance || 0
            rows.push(
                <TableRow key={i}>
                    <TableCell textAlign='center'>
                        <Popup wide trigger={<Icon circular name="info" size="small" />} position="bottom left">
                            {order.id}
                        </Popup>
                    </TableCell>
                    <TableCell>{format(new Date(order.date), 'd MMM yyyy,  h:mm a')}</TableCell>
                    <TableCell>{order.game}
                        <Popup wide trigger={<Icon circular name="info" size="small" />} position="bottom left">
                            Package: {order.package}
                        </Popup>
                    </TableCell>
                    <TableCell>{order.userName}
                        <Popup wide trigger={<Icon circular name="info" size="small" />} position="bottom left">
                            User ID: {order.userID}<br />Server ID: {order.serverID}
                        </Popup>
                    </TableCell>
                    <TableCell>{order.item.replace('Diamonds', '💎')}
                    { this.state.innerWidth < 483 ? 
                        <Popup wide trigger={<Icon circular name="info" size="small" />} position="bottom left">
                            Amount: ${order.amount}
                        </Popup> : null
                        }
                    </TableCell>
                    { this.state.innerWidth < 483 ? null : <TableCell>{order.amount}
                        { isAdmin(this.props) ? 
                        <Popup wide trigger={<Icon circular name="info" size="small" />} position="bottom left">
                            Cost: {order.cost}
                        </Popup> : null
                        }
                    </TableCell> }
                    { isAdmin(this.props) ? <TableCell>{order.reseller.name}</TableCell> : null }
                    { this.state.innerWidth < 600 ? null : <TableCell>{oldBalance.toFixed(2)}</TableCell> }
                    { this.state.innerWidth < 600 ? null : <TableCell>{newBalance.toFixed(2)}</TableCell> }
                    <TableCell textAlign='center'>{emojifyStatus(order.status)}{ this.showRefreshButtonForOrder(order) ? <Button style={{ marginLeft: 10}} size='tiny' circular basic icon onClick={(e) => this.handleRefreshOrderStatus(e, order)}><Icon name="refresh" /></Button> : null }</TableCell>
                </TableRow>
            )
        }
        return rows
    }

    renderTable() {
        console.log(this.state.innerWidth)
        if (this.props.orders == null) return
        return (
            <Table size={this.state.innerWidth < 768 ? 'small' : '' } color='teal' celled striped unstackable style={{ marginTop: 20 }}>
            <TableHeader>
                <TableRow>
                    <TableHeaderCell collapsing={this.state.innerWidth > 767}>Order ID</TableHeaderCell>
                    <TableHeaderCell>Date</TableHeaderCell>
                    <TableHeaderCell>Game</TableHeaderCell>
                    <TableHeaderCell>Player Name</TableHeaderCell>
                    <TableHeaderCell>Item</TableHeaderCell>
                    { this.state.innerWidth < 483 ? null : <TableHeaderCell>Amount</TableHeaderCell> }
                    { isAdmin(this.props) ? <TableHeaderCell>Reseller</TableHeaderCell> : null }
                    { this.state.innerWidth < 600 ? null : <TableHeaderCell>Old Balance</TableHeaderCell> }
                    { this.state.innerWidth < 600 ? null : <TableHeaderCell>New Balance</TableHeaderCell> }
                    <TableHeaderCell>Status</TableHeaderCell>
                </TableRow>
            </TableHeader>
            <TableBody>
            {this.renderTableRows()}
            </TableBody>
            </Table>
        )
    }

    render() {
        return (
            <Container>
                <Grid style={{ marginTop: 20 }}>
                    <GridColumn floated='left' width={5}><h1>Orders</h1></GridColumn>
                    <GridColumn width={6}><Input fluid action={{ icon: 'search' }} placeholder='Search order...' onChange={this.handleChangeSearchField} /></GridColumn>
                    <GridColumn floated='right' width={5}>
                        {this.showRefreshAllStatusButton() ? <Button style={{ marginLeft: 15, marginRight: 15 }} loading={this.state.isRefreshingAllStatus} color='teal' onClick={() => this.handleRefreshAllStatusButtonClicked()}><Icon name='refresh'/>Refresh All Status</Button> : null }
                        <Button color='teal' floated='right' onClick={() => this.handleExportToExcel(this.props.orders)}><Icon name='cloud download'/>Export to Excel</Button>
                    </GridColumn>
                </Grid>
                {this.renderTable()}
                {this.renderPagination()}
            </Container>
        )
    }

    handlePageChange = (e, { activePage}) => {
        let page = activePage - 1
        this.setState({ activePage: page })
    }

    showRefreshAllStatusButton() {
        var counter = 0
        for (const order of this.props.orders || []) {
            if (this.showRefreshButtonForOrder(order)) counter += 1
        }
        if (counter > 1) return true
        else return false
    }

    showRefreshButtonForOrder(order) {
        if (order.status==='new' || order.status==='pending') return true
        else return false
    }

    handleChangeSearchField = e => {
        const { value } = e.target
        if (value === "") {
            this.setState({ isSearching: false })
        } else {
            this.setState({ isSearching: true, searchString: value })
        }
    }

    handleRefreshOrderStatus = (e, order) => {
        e.stopPropagation()
        axios.post('/api/reseller/order/' + order.id + '/refresh_status').then(res => {
            this.props.fetchOrders(isAdmin(this.props), this.props.user._id)
        })
        .catch(err => {
            console.log(`POST /api/reseller/order error: ${err}`)
            alert(`Failure refreshing order status, please try again. Error: ${err}`)
        })
    }

    handleRefreshAllStatusButtonClicked() {
        this.setState({ isRefreshingAllStatus: true })
        axios.post('/api/reseller/orders/refresh_all_status').then(res => {
            this.setState({ isRefreshingAllStatus: false })
            this.props.fetchOrders(isAdmin(this.props), this.props.user._id)
        })
        .catch(err => {
            this.setState({ isRefreshingAllStatus: false })
            console.log(`POST api/reseller/orders/refresh_all_status error: ${err}`)
            alert(`Failure refreshing order status, please try again. Error: ${err}`)
        })
    }

    async handleExportToExcel(orders) {
        var sheets = []
        sheets.push("Orders")
        var data = []
        var arr = this.generateHeaderRowForSheet(sheets[0])
        data.push(arr)
        for (const order of orders) {
            var profit = 0
            if (!!order.amount && !!order.cost) profit = Number(order.amount) - Number(order.cost)
            const arr = [order.id, order.date, order.game, order.package, order.userName, order.userID, order.serverID, order.item, order.amount, order.cost || '-', profit, order.reseller.name, order.reseller.oldBalance, order.reseller.newBalance, order.status]
            if (!isAdmin(this.props)) arr.splice(9, 3)
                var row = []
            for (const value of arr) {
                const dict = { value }
                row.push(dict)
            }
            data.push(row)
        }
        const blob = await writeXlsxFile([data], {sheets: sheets, stickyRowsCount: 1})
        let formattedDate = format(Date(), "d MMMM yyyy hh-mm a")
        saveAs(blob, `EGC-Orders-${formattedDate}.xlsx`)
    }

    generateHeaderRowForSheet(sheet) {
        const arr = ['Order ID', 'Date', 'Game', 'Package', 'Player Name', 'User ID', 'Server ID', 'Item', 'Amount', 'Cost', 'Profit', 'Reseller', 'Old Balance', 'New Balance', 'Status']
        if (!isAdmin(this.props)) arr.splice(9, 3)
        var headerRow = []
        for (const value of arr) {
            const dict = { value, fontWeight: 'bold'}
            headerRow.push(dict)
        }
        return headerRow
    }
}

function emojifyStatus(status) {
    if (status === 'successful') return '✅'
    if (status === 'pending') return '⏳'
    else return '❌'
}

function mapStateToProps({ orders, user }) {
    return { orders, user }
}

export default connect(mapStateToProps, actions)(Orders)