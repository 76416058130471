import React, { Component } from "react"
import axios from "axios"
import { Container, Form, FormGroup, FormField, Input, TextArea, Checkbox, Button, Icon, Message, Confirm } from 'semantic-ui-react'

class Settings extends Component {
    state = { priority: 0, productSource: '', priceMarkup: '', elitediasName: '', requiresServerID: false, requiresCharacterName: false, instructionalImage: '', hasPackages: false, redemptionCodes: [], isSyncingPricesMLBB: false, showSyncSuccessMessage: false, 
            showDeleteConfirmationPopup: false }

    componentDidMount() {
        console.log(this.props.savedDict)
        if (this.props.savedDict) { 
            const { priority, productSource, priceMarkup, elitediasName, requiresServerID, requiresCharacterName, instructionalImage, hasPackages, redemptionCodes, isHidden } = this.props.savedDict
            this.setState({ priority, productSource, priceMarkup, elitediasName, requiresServerID, requiresCharacterName, instructionalImage, hasPackages, redemptionCodes, isHidden })
        }
    }

    render() {
        const { priority, productSource, priceMarkup, elitediasName, requiresServerID, requiresCharacterName, hasPackages, instructionalImage, redemptionCodes, isHidden, isSyncingPricesMLBB, showSyncSuccessMessage } = this.state
        return (
            <Container>
                { showSyncSuccessMessage ? <Message style={{ marginTop: 20 }} success header={'Successfully synced prices with Elite Dias'} content='For packages mlbb_global and mlbb_special.'/> : null }
                { elitediasName === 'mlbb' ? <Button loading={isSyncingPricesMLBB} style={{ marginTop: 10 }} color='blue' onClick={() => this.handleSyncPricesMLBB()}><Icon name='refresh'/>Sync Prices with Elite Dias for mlbb_global and mlbb_special</Button> : null }
                <Form style={{ marginTop: 25 }}>
                    <FormField control={Checkbox} toggle label='Is Hidden' name='isHidden' checked={isHidden} onChange={this.handleToggle}/>
                    <FormField control={Input} label='Priority' name='priority' value={priority} placeholder='Priority' onChange={this.handleChangeField}/>
                    <FormField control={Input} label='Product Source' name='productSource' value={productSource} placeholder='Product Source' onChange={this.handleChangeField}/>
                    <FormField control={Input} label='Price Mark Up' name='priceMarkup' value={priceMarkup} placeholder='Price Mark Up' onChange={this.handleChangeField}/>
                    <FormField control={Input} label='Elite Dias Name' name='elitediasName' value={elitediasName} placeholder='Elite Dias Name' onChange={this.handleChangeField}/>
                    <FormGroup widths={"equal"}>
                        <FormField control={Input} label='Requires Server ID' name='requiresServerID' value={requiresServerID} placeholder='Requires Server ID' onChange={this.handleChangeField}/>
                        <FormField control={Input} label='Requires Character Name' name='requiresCharacterName' value={requiresCharacterName} placeholder='Requires Character Name' onChange={this.handleChangeField}/>
                        <FormField control={Input} label='Has Packages' name='hasPackages' value={hasPackages} placeholder='Has Packages' onChange={this.handleChangeField}/>
                    </FormGroup>
                    <FormField control={Input} label='Instructional Image' name='instructionalImage' value={instructionalImage} placeholder='Instructional Image' onChange={this.handleChangeField}/>
                    <FormField control={TextArea} rows='4' label="Redemption Codes" name='redemptionCodes' value={redemptionCodes} placeholder='' onChange={this.handleChangeField}/>
                </Form>
                <Button style={{ marginTop: 20 }} color='red' onClick={() => this.handleDeleteClicked()}><Icon name='trash'/>Delete this game</Button>
                <Confirm open={this.state.showDeleteConfirmationPopup} onCancel={this.handleDeleteCancel} onConfirm={() => this.handleDeleteConfirm()} header="Confirmation" content='Delete this game?' cancelButton="Cancel" confirmButton="Delete"/>
            </Container>
        )
    }

    handleChangeField = e => {
        const { name, value } = e.target
        this.setState({ [name]: value }, () => this.props.onStateChange(this.state))
    }

    handleToggle = (e, data) => {
        this.setState({ [data.name]: data.checked }, () => this.props.onStateChange(this.state))
    }

    async handleSyncPricesMLBB() {
        this.setState({ isSyncingPricesMLBB: true })
        await axios.post("/api/update_elite_dias_prices").then(res => {
            console.log(res.data)
            this.setState({ isSyncingPricesMLBB: false, showSyncSuccessMessage: true })
        }).catch(err => {
            console.log(`handleSyncPricesMLBB failed with error: ${err}`)
            alert(`Failure syncing prices for mlbb packages, please try again. Error: ${err}`)
            this.setState({ isSyncingPricesMLBB: false })
        })
    }

    handleDeleteClicked = () => {
        if (this.state.showDeleteConfirmationPopup) return
        this.setState({ showDeleteConfirmationPopup: true })
    }

    handleDeleteCancel = () =>
        this.setState({ showDeleteConfirmationPopup: false })

    async handleDeleteConfirm() {
        await axios.delete("/api/game/" + this.props.savedDict.id).then(_ => {
            this.setState({ showDeleteConfirmationPopup: false })
            this.props.gameDeleted()
        })
        .catch(err => {
            console.log(`delete reseller failed with error: ${err}`)
            alert(`Failure deleting reseller, please try again. Error: ${err}`)
        })
    }
}

export default Settings