import React, { Component } from "react"
import { Container, Table, TableHeader, TableHeaderCell, TableBody, TableRow, TableCell, Button, Icon, Grid, GridColumn } from 'semantic-ui-react'
import Package from "./Package"
import Merchandise from "./Merchandise"

class Packages extends Component {
    state = { packages: [], isSearching: false, searchString: '', isEditing: false, editingPackage: {}, editingIndex: 0 }

    constructor(props) {
        super(props)
        this.handlePackageStateChange = this.handlePackageStateChange.bind(this)
    }
    
    componentDidMount() {
        console.log(this.props.savedDict)
        if (this.props.savedDict) { 
            const { packages } = this.props.savedDict
            this.setState({ packages })
        }
    }

    renderTableRows() {
        const { packages, isSearching, searchString } = this.state
        if (packages === undefined) return
        const data = isSearching ? packages.filter((pkg) => pkg.name?.toLowerCase()?.includes(searchString.toLowerCase())) : packages
        var rows = []
        for (const [i, pkg] of data.entries()) {
            if (this.props.isReseller && pkg.isHidden) continue
            rows.push(  <TableRow key={i} style={{ cursor: 'pointer' }} onClick={() => this.handlePackageClicked(pkg, i)}>
                            <TableCell>{pkg.name}</TableCell>
                            { !this.props.isReseller ? <TableCell>{pkg.eliteDiasPackageName}</TableCell> : null }
                            { !this.props.isReseller ? <TableCell>{pkg.isHidden ? 'Yes' : 'No' }</TableCell> : null }
                            { !this.props.isReseller ? <TableCell>{pkg.gameItems.length}</TableCell> : null }
                        </TableRow>)
        }
        return rows
    }

    renderTable() {
        if (this.state.packages == null) {
            return <h4>This game has no packages.</h4>
        } else {
            return (
                <Table color='yellow' celled striped style={{ marginTop: 20 }}>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell>Name</TableHeaderCell>
                        { !this.props.isReseller ? <TableHeaderCell>Elite Dias Name</TableHeaderCell> : null }
                        { !this.props.isReseller ? <TableHeaderCell width='2'>Is Hidden</TableHeaderCell> : null }
                        { !this.props.isReseller ? <TableHeaderCell width='2'>Item Count</TableHeaderCell> : null }
                    </TableRow>
                </TableHeader>
                <TableBody>
                {this.renderTableRows()}
                </TableBody>
              </Table>
            )
        }
    }

    renderPackageDetails() {
        return <Package savedDict={this.state.editingPackage} backClicked={() => this.handlePackageBackClicked()} onStateChange={this.handlePackageStateChange}/>
    }

    render() {
        return (
            <Container>
                { this.props.isReseller && !this.state.isEditing ? 
                <Grid style={{ marginTop: 20 }}>
                    <GridColumn floated='left' width={5}><h2>{this.props.savedDict.name}</h2></GridColumn>
                    <GridColumn width={6}></GridColumn>
                    <GridColumn floated='right' width={5}><Button floated='right' onClick={this.props.resellerBackClicked} color='blue'><Icon name='reply'/>Back</Button></GridColumn>
                </Grid> : null }
                { !this.state.isEditing ? this.renderTable() : this.props.isReseller ? <Merchandise isReseller savedDict={this.state.editingPackage} resellerBackClicked={() => this.handlePackageBackClicked()}/> : this.renderPackageDetails() }
            </Container>
        )
    }

    handleChangeField = e => {
        const { name, value } = e.target
        this.setState({ [name]: value }, () => this.props.onStateChange(this.state))
    }

    handlePackageClicked(pkg, i) {
        this.setState({ isEditing: true, editingPackage: pkg, editingIndex: i })
    }

    handlePackageStateChange(pkg) {
        var packages = [...this.state.packages] || []
        packages[this.state.editingIndex] = pkg
        this.setState({ packages }, () => this.props.onStateChange(this.state))
    }

    handlePackageBackClicked() {
        this.setState({ isEditing: false, editingPackage: {} })
    }
}

export default Packages