import { combineReducers } from "redux"
import authReducer from './authReducer'
import userReducer from './userReducer'
import usersReducer from './usersReducer'
import twoFAReducer from './twoFAReducer'
import gamesReducer from './gamesReducer'
import ordersReducer from './ordersReducer'
import resellersReducer from './resellersReducer'
import settingsReducer from './settingsReducer'

export default combineReducers({
    auth: authReducer,
    user: userReducer,
    users: usersReducer,
    games: gamesReducer,
    orders: ordersReducer,
    resellers: resellersReducer,
    verifyToken: twoFAReducer,
    settings: settingsReducer,
})