import { React, Component } from 'react'
import { connect } from "react-redux"
import * as actions from "../../actions"
import NavBar from "./NavBar"
import CreateGame from './games/CreateGame'
import Games from './games/Games'
import Game from './games/Game'
import CreateReseller from './resellers/CreateReseller'
import Resellers from './resellers/Resellers'
import Reseller from './resellers/Reseller'
import ResellerMLBB from './games/ResellerMLBB'
import Users from "./users/Users"
import User from "./users/User"
import Orders from "./orders/Orders"
import Merchandise from './games/Merchandise'
import Packages from './games/Packages'
import Settings from './settings/Settings'
import Account from './account/Account'
import { ENABLE_2FA } from '../../utilities/enable2fa'

var resellerGameDict = {}

class Home extends Component {

    state = { activeItem: 'games', gameToEdit: {}, userToEdit: {}, resellerToEdit: {} }

    constructor(props) {
        super(props)
        this.props.getCurrentUser()
        this.handleChangeItemNavBar = this.handleChangeItemNavBar.bind(this)
        // Game
        this.handleChangeItemGame = this.handleChangeItemGame.bind(this)
        this.handleCreateGameSuccess = this.handleCreateGameSuccess.bind(this)
        this.handleResellerGameClicked = this.handleResellerGameClicked.bind(this)
        this.handleBackClickedGames = this.handleBackClickedGames.bind(this)
        // Reseller
        this.handleCreateResellerSuccess = this.handleCreateResellerSuccess.bind(this)
        this.handleChangeItemReseller = this.handleChangeItemReseller.bind(this)
        this.handleBackClickedResellers = this.handleBackClickedResellers.bind(this)
        this.handleBackClickedMerchandise = this.handleBackClickedMerchandise.bind(this)
        // User
        this.handleCreateUserSuccess = this.handleCreateUserSuccess.bind(this)
        this.handleChangeItemUser = this.handleChangeItemUser.bind(this)
        this.handleBackClickedUsers = this.handleBackClickedUsers.bind(this)
        if (!!this.props.verifyToken) {
            window.localStorage.setItem('verified2FA', 'yup')
        }
    }

    renderContent() {
        switch (this.state.activeItem) {
            case "games":
                return <Games onChangeItem={this.handleChangeItemGame} resellerGameClicked={this.handleResellerGameClicked}/>
            case "new-game":
                return <CreateGame onCreateSuccess={this.handleCreateGameSuccess} onCancel={this.handleBackClickedGames}/>
            case "edit-game":
                return <Game gameToEdit={this.state.gameToEdit} onCancel={this.handleBackClickedGames}/>
            case "resellers":
                return <Resellers onChangeItem={this.handleChangeItemReseller}/>
            case "new-reseller":
                return <CreateReseller onCreateSuccess={this.handleCreateResellerSuccess} onCancel={this.handleBackClickedResellers}/>
            case "edit-reseller":
                return <Reseller isEditing reseller={this.state.resellerToEdit} onCancel={this.handleBackClickedResellers}/>
            case "users":
                return <Users onChangeItem={this.handleChangeItemUser}/>
            case "new-user":
                return <User onCreateSuccess={this.handleCreateUserSuccess} onCancel={this.handleBackClickedUsers}/>
            case "edit-user":
                return <User isEditing userToEdit={this.state.userToEdit} onCancel={this.handleBackClickedUsers}/>
            case "orders":
                return <Orders/>
            case "merchandise":
                return <Merchandise isReseller savedDict={resellerGameDict} resellerBackClicked={this.handleBackClickedMerchandise}/>
            case "packages":
                return <Packages isReseller savedDict={resellerGameDict} resellerBackClicked={this.handleBackClickedMerchandise}/>
            case "reseller-mlbb":
                return <ResellerMLBB game={resellerGameDict} markup={this.props.user.markup} backClicked={this.handleBackClickedGames}/>
            case "settings":
                return <Settings/>
            case "account":
                return <Account/>
            default: 
                return 
        }
    }

    render() {
        if (ENABLE_2FA) if (window.localStorage.getItem('verified2FA') !== 'yup') return
        if (this.props.user === undefined || this.props.user == null) return
        if (this.props.user.name === undefined || this.props.user.name == null) return
        return (
            <div style={{ paddingBottom: 30 }}>
                <NavBar activeItem={this.state.activeItem} onChangeItem={this.handleChangeItemNavBar} />
                {this.renderContent()}
            </div>
        )
    }

    handleChangeItemNavBar(item) {
        this.setState({ activeItem: item })
    }

    handleChangeItemGame(item, game) {
        if (game && item === 'edit-game') this.setState({ gameToEdit: game })
        this.setState({ activeItem: item })
    }
    handleCreateGameSuccess() {
        this.setState({ activeItem: 'games' })
    }
    handleResellerGameClicked(game) {
        resellerGameDict = game
        if (game.name === 'Mobile Legends') this.setState({ activeItem: 'reseller-mlbb' })
        else this.setState({ activeItem: game.hasPackages ? 'packages' : 'merchandise' })
    }
    handleBackClickedMerchandise() {
        this.setState({ activeItem: 'games' })
    }
    handleBackClickedGames() {
        this.setState({ activeItem: 'games' })
    }

    handleChangeItemReseller(item, reseller) {
        if (reseller && item === 'edit-reseller') this.setState({ resellerToEdit: reseller })
        this.setState({ activeItem: item })
    }
    handleCreateResellerSuccess() {
        this.setState({ activeItem: 'resellers' })
    }
    handleBackClickedResellers() {
        this.setState({ activeItem: 'resellers' })
    }

    handleChangeItemUser(item, user) {
        if (user && item === 'edit-user') this.setState({ userToEdit: user })
        this.setState({ activeItem: item })
    }
    handleCreateUserSuccess() {
        this.setState({ activeItem: 'users' })
    }
    handleBackClickedUsers() {
        this.setState({ activeItem: 'users' })
    }
}

function mapStateToProps({ user, roles, verifyToken }) {
    return { user, roles, verifyToken }
}

export default connect(mapStateToProps, actions)(Home)

