import React, { Component  } from "react"
import axios from 'axios'
import Details from './Details'
import Merchandise from './Merchandise'
import Packages from "./Packages"
import Links from './Links'
import Settings from './Settings'
import { Container, Breadcrumb, BreadcrumbSection, BreadcrumbDivider, Button, Menu, MenuItem, Icon, Message } from 'semantic-ui-react'

var detailsDict = {}
var merchandiseDict = {}
var packagesDict = {}
var linksDict = {}
var settingsDict = {}

class Game extends Component {
    state = { filesChanged: [], activeItem: '', showSuccessMessage: false }
  
    constructor(props) {
        super(props)
        this.handleMenuItemClicked = this.handleMenuItemClicked.bind(this)
        this.handleCancelClicked = this.handleCancelClicked.bind(this)
        this.handleEntriesChangeItem = this.handleEntriesChangeItem.bind(this)
        this.handleEventsChangeItem = this.handleEventsChangeItem.bind(this)
        this.handleFileChange = this.handleFileChange.bind(this)
        this.handleDeleteGame = this.handleDeleteGame.bind(this)
        this.handlePackagesStateChange = this.handlePackagesStateChange.bind(this)
        if (!this.props.isEditing) {
            this.resetDicts()
        }
    }

    componentDidMount() {
        const game = this.props.gameToEdit
        detailsDict = getDictFromGame('details', game)
        merchandiseDict = getDictFromGame('merchandise', game)
        packagesDict = getDictFromGame('packages', game)
        linksDict = getDictFromGame('links', game)
        settingsDict = getDictFromGame('settings', game)
        this.setState({ activeItem: 'details' })
    }

    renderContent() {
        switch (this.state.activeItem) {
            case 'details':
                return <Details savedDict={detailsDict} onStateChange={this.handleDetailsStateChange}/>
            case 'merchandise':
                return <Merchandise savedDict={merchandiseDict} onStateChange={this.handleMerchandiseStateChange}/>
            case 'packages':
                return <Packages savedDict={packagesDict} onStateChange={this.handlePackagesStateChange}/>
            case 'links':
                return <Links savedDict={linksDict} onStateChange={this.handleLinksStateChange}/>
            case 'settings':
                return <Settings savedDict={settingsDict} onStateChange={this.handleSettingsStateChange} gameDeleted={this.handleDeleteGame}/>
            default:
                return
        }
    }

    render() {
        return (
            <Container>
                <Breadcrumb style={{ marginTop: 20, marginBottom: 10 }} size='large'>
                    <BreadcrumbSection link onClick={this.handleCancelClicked}>Games</BreadcrumbSection>
                    <BreadcrumbDivider icon='right chevron' />
                    <BreadcrumbSection active>{this.props.gameToEdit.name}</BreadcrumbSection>
                </Breadcrumb>
                <Menu tabular>
                    <MenuItem name='Details' active={this.state.activeItem === 'details'} onClick={this.handleMenuItemClicked}/>
                    <MenuItem name='Merchandise' active={this.state.activeItem === 'merchandise'} onClick={this.handleMenuItemClicked}/>
                    <MenuItem name='Packages' active={this.state.activeItem === 'packages'} onClick={this.handleMenuItemClicked}/>
                    <MenuItem name='Links' active={this.state.activeItem === 'links' || this.state.activeItem === 'new-entry'} onClick={this.handleMenuItemClicked}/>
                    <MenuItem name='Settings' active={this.state.activeItem === 'settings'} onClick={this.handleMenuItemClicked}/>
                </Menu>
                {this.renderContent()}
                <br/><br/><br/><br/><br/><br/>
                <div style={{ position: "fixed", bottom: 0, left: 0, right: 0, background: "#fff", borderTop: "2px solid #ccc", padding: "10px", zIndex: 100 }}>
                    <Container>
                        { this.state.showSuccessMessage ? <Message style={{ marginTop: 20 }} success header='Changes saved successfully.'/> : null }
                        <Button style={{ marginTop: 10, marginRight: 15, marginBottom: 15 }} onClick={this.handleCancelClicked}><Icon name="reply" />Back</Button>
                        <Button style={{ backgroundColor: '#007BFF', marginRight: 15 }} color='black' onClick={this.handleSaveClicked}><Icon name="save" />Save</Button>
                    </Container>
                </div>
            </Container>
        )
    }

    handleMenuItemClicked(e) {
        switch (e.target.innerText) {
            case "Details":
                this.setState({ activeItem: "details" })
                break
            case "Merchandise":
                this.setState({ activeItem: "merchandise" })
                break
            case "Packages":
                this.setState({ activeItem: "packages" })
                break
            case "Links":
                this.setState({ activeItem: "links" })
                break
            case "Settings":
                this.setState({ activeItem: "settings" })
                break
            default:
                break
        }
    }

    handleEventsChangeItem(item) {
        if (item === 'new-event') this.setState({ activeItem: item })
    }

    handleEntriesChangeItem(item) {
        if (item === 'new-entry') this.setState({ activeItem: item })
    }

    handleDetailsStateChange = state => detailsDict = state
    handleMerchandiseStateChange = state => merchandiseDict = state
    handlePackagesStateChange = state => {
        packagesDict = state
        this.setState({ showSuccessMessage: false })
    }
    handleLinksStateChange = state => linksDict = state
    handleSettingsStateChange = state => settingsDict = state

    handleFileChange(file, type) {
        var files = this.state.filesChanged
        for (const [i, fileDict] of files.entries()) {
            if (fileDict.type === type) {
                files.splice(i, 1)
                break  
            } 
        }
        let dict = { file, type }
        files.push(dict)
        this.setState({ filesChanged: files })
    }

    handleSaveClicked = async () => {
        var dict = getCombinedDict()
        await axios.put("/api/game/" + this.props.gameToEdit._id, dict).then(_ => {
            this.setState({ showSuccessMessage: true })
        }).catch(err => {
            console.log(`edit game failed with error: ${err}`)
            alert(`Failure editing game, please try again. Error: ${err}`)
        })
    }

    handleCancelClicked() {
        if (this.state.activeItem === 'new-entry') {
            this.setState({ activeItem: 'entries' })
        } else if (this.state.activeItem === 'new-event') {
            this.setState({ activeItem: 'events' })
        } else {
            this.resetDicts()
            this.props.onCancel()
        }
    }

    resetDicts() {
        detailsDict = {}
        merchandiseDict = {}
        packagesDict = {}
        linksDict = {}
        settingsDict = {}
    }

    handleDeleteGame() {
        this.resetDicts()
        this.props.onCancel()
    }
}

function getCombinedDict() {
    var dict = { ...detailsDict, ...merchandiseDict, ...packagesDict, ...linksDict, ...settingsDict }
    return dict
}

function getDictFromGame(str, game) {
    switch (str) {
        case "details":
            const { name, tagline, description, gameImage, aboutThisGame, faq, platforms } = game
            return { name, tagline, description, gameImage, aboutThisGame, faq, platforms }
        case "merchandise":
            return { gameItems: game.gameItems }
        case "packages":
            return { packages: game.packages }
        case "links":
            const { newMetaUpdateLinks, generalGuideLinks, howToPlayLinks, esportsEventLinks, memesFunnyLinks, officialWebsiteLink, fandomLink, redditLink } = game
            return { newMetaUpdateLinks, generalGuideLinks, howToPlayLinks, esportsEventLinks, memesFunnyLinks, officialWebsiteLink, fandomLink, redditLink }
        case "settings":
            const { _id, priority, productSource, priceMarkup, elitediasName, requiresServerID, requiresCharacterName, instructionalImage, hasPackages, redemptionCodes, isHidden } = game
            return { id: _id, priority, productSource, priceMarkup, elitediasName, requiresServerID, requiresCharacterName, instructionalImage, hasPackages, redemptionCodes, isHidden }
        default:
            return {}
    }
}

export default Game