import { React, Component } from 'react'
import Login from './Login'

class Landing extends Component {
    render() {
        return (
            <Login userType='reseller' history={this.props.history}/>
        )
    }
}

export default Landing