import React, { Component } from "react"
import { BrowserRouter, Route } from "react-router-dom"
import { connect } from "react-redux"
import * as actions from "../actions"

import Landing from './Landing'
import LandingAdmin from './LandingAdmin'
import LandingResellers from './LandingResellers'
import TwoFAPage from './TwoFAPage'
import Home from './home/Home'

const originalConsoleError = console.error
console.error = (...args) => {
    if (typeof args[0] === "string" && (args[0].includes("defaultProps") || args[0].includes('findDOMNode') || args[0].includes('children') || args[0].includes('non-boolean'))) {
        return // Suppress this specific warning
    }
    originalConsoleError(...args)
}

class App extends Component {
    state = { isMobile: false }
    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this))
        this.setState({ isMobile: window.innerWidth <= 760 })
    }

    resize() {
        this.setState({ isMobile: window.innerWidth <= 760 })
    }

    render() {
        return (
            <div className="container"> 
                <BrowserRouter>
                    <div>
                        <Route exact path="/jianrongishandsome" component={Landing} />
                        <Route exact path="/admin" component={LandingAdmin} />
                        <Route exact path="/resellers" component={LandingResellers} />
                        <Route exact path="/2fa" component={TwoFAPage} />
                        <Route exact path="/home" component={Home} />
                    </div>
                </BrowserRouter>
            </div>
        )
    } 
}

export default connect(null, actions)(App)

