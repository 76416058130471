import React, { Component } from "react"
import { connect } from "react-redux"
import * as actions from "../../../actions"
import axios from "axios"
import { isAdmin } from "../../../utilities/isAdmin"
import { Container, Button, Message, Form, FormField, Icon, Input, Divider, Header, Table } from 'semantic-ui-react'

class Account extends Component {
    state = { fxRateMarkUp: '', password: '', showSuccessMessage: false }

    componentDidMount() {
        this.props.fetchSettings()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.settings !== this.props.settings) {
            console.log(this.props.settings)
            this.setState({ fxRateMarkUp: this.props.settings.fxRateMarkUp })
        }
    }

    render() {
        const { fxRateMarkUp, password, showSuccessMessage } = this.state
        return (
            <Container>
                <h1 style={{ marginTop: 20, marginBottom: 30 }}>My Account</h1>
                { showSuccessMessage ? <Message success header='Successfully changed password'/> : null }
                <Message style={{ marginBottom: 30 }} info icon='dollar' header='Exchange Rate' content='Please note that our current exchange rate is 1 USD to x SGD.' />
                <Form>
                    <FormField control={Input} label='Change Account Password' value={password} placeholder='New password' type='password' onChange={this.handleChangePassword}/>
                    <Button style={{ marginTop: 15 }} color='blue' size='small' onClick={() => this.saveToServer()}><Icon name='save'/>Save</Button> 
                </Form>
                <Button style={{ marginTop: 15 }} size='small' color='red' onClick={this.handleLogout}>Sign out</Button>
                <Divider style={{ marginTop: 40 }} horizontal><Header as='h4'><Icon name='history' />Account Balance History</Header></Divider>
                <Table>
            
                </Table>
            </Container>
        )
    }

    handleChangePassword = e => {
        this.setState({ password: e.target.value })
    }

    handleLogout = () => {
        window.localStorage.setItem('verified2FA', null)
        this.props.logout(isAdmin(this.props))
    }
    
    saveToServer() {
        let dict = { id: this.props.user._id, password: this.state.password }
        axios.post("/api/reseller/change_password", dict)
        .then(() => { 
            this.setState({ showSuccessMessage: true })
        })
        .catch(err => {
            console.log("save settings failed with error: " + err)
            alert('Fail to save settings, please try again. Error: ' + err)
        })
    }
}

function mapStateToProps({ settings, user }) {
    return { settings, user }
}

export default connect(mapStateToProps, actions)(Account)