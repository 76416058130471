import React, { Component } from "react"
import { connect } from "react-redux"
import * as actions from "../../../actions"
import axios from "axios"
import { Container, Button, Message, Form, FormField, Icon, Input } from 'semantic-ui-react'

class Settings extends Component {
    state = { fxRateMarkUp: '', showSuccessMessage: false }

    componentDidMount() {
        this.props.fetchSettings()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.settings !== this.props.settings) {
            console.log(this.props.settings)
            this.setState({ fxRateMarkUp: this.props.settings.fxRateMarkUp })
        }
    }

    render() {
        const { fxRateMarkUp, showSuccessMessage } = this.state
        return (
            <Container>
                <h1 style={{ marginTop: 20, marginBottom: 30 }}>Settings</h1>
                { showSuccessMessage ? <Message success header='Successfully saved changes.'/> : null }
                <Form>
                    <FormField control={Input} label='Exchange Rate Mark Up (SGD cents per 1 USD)' name='fxRateMarkUp' value={fxRateMarkUp} placeholder='Mark up' onChange={this.handleChangeMarkUp}/>
                    <Button style={{ marginTop: 20 }} color='blue' size='small' onClick={() => this.saveToServerSettings()}><Icon name='save'/>Save</Button>
                </Form>
            </Container>
        )
    }

    handleChangeMarkUp = e => {
        this.setState({ fxRateMarkUp: e.target.value })
    }
    
    saveToServerSettings() {
        let dict = { fxRateMarkUp: Number(this.state.fxRateMarkUp) }
        axios.put("/api/settings", dict)
        .then(() => { 
            this.setState({ showSuccessMessage: true })
        })
        .catch(err => {
            console.log("save settings failed with error: " + err)
            alert('Fail to save settings, please try again. Error: ' + err)
        })
    }
}

function mapStateToProps({ settings }) {
    return { settings }
}

export default connect(mapStateToProps, actions)(Settings)