import React, { Component } from "react"
import { Container, Form, FormField, Input, Segment, TextArea, Button, Icon } from 'semantic-ui-react'

class GameDetails extends Component {
    state = { name: '', tagline: '', description: '', gameImage: '', aboutThisGame: '', faq: [], platforms: [] }

    componentDidMount() {
        console.log(this.props.savedDict)
        if (this.props.savedDict) { 
            const { name, tagline, description, gameImage, aboutThisGame, faq, platforms } = this.props.savedDict
            this.setState({ name, tagline, description, gameImage, aboutThisGame, faq, platforms })
        }
    }

    renderFAQs() {
        const { faq } = this.state
        var rows = []
        if (faq === undefined) return
        for (const [i, faqItem] of faq.entries()) {
            rows.push(<Segment key={i} style={{ padding: 20 }}>
                <FormField control={Input} label='Question' name='Question' value={faqItem.question} placeholder='Question' onChange={this.handleChangeFieldFAQ}/>
                <FormField style={{ marginBottom: 5 }} control={TextArea} rows='6' label='Answer' name='Answer' value={faqItem.answer} placeholder='Answer' onChange={this.handleChangeFieldFAQ}/>
            </Segment>)
        }
        rows.push(<Button basic style={{ marginTop: faq.length === 0 ? 0 : 8 }} color='blue' size='tiny' onClick={() => this.handleAddNewVideo()}><Icon name="add"/>Add New FAQ</Button>)
        return rows
    }

    render() {
        const { name, tagline, description, aboutThisGame, platforms } = this.state
        return (
            <Container>
                <Form style={{ marginTop: 25 }}>
                    <FormField control={Input} label='Name' name='name' value={name} placeholder='' onChange={this.handleChangeField}/>
                    <FormField control={Input} label='Tagline' name='tagline' value={tagline} placeholder='' onChange={this.handleChangeField}/>
                    <FormField control={Input} label='Platform' name='platforms' value={platforms} placeholder='' onChange={this.handleChangeField}/>
                    <FormField control={TextArea} rows='8' label="Description" name='description' value={description} placeholder='' onChange={this.handleChangeField}/>
                    <FormField control={TextArea} rows='6' label="About this Game" name='aboutThisGame' value={aboutThisGame} placeholder='' onChange={this.handleChangeField}/>
                    <h4>FAQ</h4>{this.renderFAQs()}
                </Form>
            </Container>
        )
    }

    handleChangeField = e => {
        const { name, value } = e.target
        this.setState({ [name]: value }, () => this.props.onStateChange(this.state))
    }

    handleChangeFieldFAQ = e => {
        
    }

    handleAddNewFAQ() {

    }
}

export default GameDetails